// ** React Import
import { useState, useEffect } from "react";

// ** Custom Components
import Sidebar from "@components/sidebar";

// ** Reactstrap Imports
import { Button, Form, Input, Spinner } from "reactstrap";

// ** Store & Actions
import { connect, useDispatch } from "react-redux";
// import FileUploaderSingle from "../../forms/form-elements/file-uploader/FileUploaderSingle";
import BotEllipse from "@src/assets/images/logo/Ellipse 11.png";
// import { uploadDocument } from "../../../redux/actions";
// import { uploadFileToS3 } from "../../../services/aws-services/info_aws_s3_config";
// import uploadFileToS3 from "../../../services/aws-services/info_aws_s3_config";
// import pusher from "../../../services/pusher";
import { Link } from "react-router-dom";

const checkIsValid = data => {
  return Object.values(data).every(field => (typeof field === "object" ? field !== null : field.length > 0));
};

const SidebarDashboard = ({ open, toggleSidebar, uploadDocument }) => {
  // ** States
  const [querySearchEnabled, enableQuerySearchOption] = useState(false);
  const [uploaderText, setUploadingMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [url, setUrl] = useState("");
  const [previewError, setPreviewError] = useState(false);
  const [userName, setUserName] = useState("");
  const [showDiv1, setShowDiv1] = useState(false);
  const [showDiv2, setShowDiv2] = useState(false);
  const [showDiv3, setShowDiv3] = useState(false);
  const [showDiv4, setShowDiv4] = useState(false);
  const [showDiv5, setShowDiv5] = useState(false);
  const [previewData, setPreviewData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [files, setFilesData] = useState([]);

  // ** Store Vars
  const dispatch = useDispatch();
  useEffect(() => {
    const first_name = window.localStorage.getItem("first_name");
    const last_name = window.localStorage.getItem("last_name");
    const username = first_name || "" + last_name || "";
    setUserName(username);
    const delay = 400; // 1 second delay

    const timeout1 = setTimeout(() => {
      setShowDiv1(true);
    }, delay);

    const timeout2 = setTimeout(() => {
      setShowDiv2(true);
    }, delay * 2); // 2 seconds delay

    const timeout3 = setTimeout(() => {
      setShowDiv3(true);
    }, delay * 3); // 3 seconds delay

    const timeout4 = setTimeout(() => {
      setShowDiv4(true);
    }, delay * 4);

    const timeout5 = setTimeout(() => {
      setShowDiv5(true);
    }, delay * 5);

    // Clear the timeouts when the component unmounts
    return () => {
      clearTimeout(timeout1);
      clearTimeout(timeout2);
      clearTimeout(timeout3);
      clearTimeout(timeout4);
      clearTimeout(timeout5);
    };
  }, []);

  const isYouTubeURL = (url) => {
    return url.includes("youtube.com") || url.includes("youtu.be");
  };

  const extractYouTubeVideoId = (url) => {
    const videoIdRegex = /(?:\/embed\/|\/watch\?v=|\/(?:embed\/|v\/|watch\?.*v=|youtu\.be\/|embed\/|v=))([^&?#]+)/;
    const match = url.match(videoIdRegex);
    return match ? match[1] : "";
  };

  const handleInputChangePaste = (e) => {
    e.preventDefault();
    setLoading(true);
    const pastedValue = (e.clipboardData || window.clipboardData).getData("text");
    setUrl(pastedValue);

    const fetchData = async () => {
      try {
        const response = await fetch(pastedValue);
        const data = await response.text();

        const isYouTubeVideo = isYouTubeURL(url);
        if (isYouTubeVideo) {
          const videoId = extractYouTubeVideoId(url);
          const videoThumbnail = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;

          setPreviewData({
            videoId,
            videoThumbnail
          });
          setLoading(false);
        } else {
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, "text/html");
          const title = doc.querySelector("title")?.textContent || "";
          const description = doc.querySelector("meta[name=\"description\"]")?.getAttribute("content") || "";
          const image = doc.querySelector("meta[property=\"og:image\"]")?.getAttribute("content") || "";
          setPreviewData({ title, description, image });
          setLoading(false);
        }
        setPreviewError(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
        setPreviewError(true);
      }
    };
    fetchData().then();
  };

  const handleClick = () => {
    window.open(url, "_blank");
  };


  const handleSidebarClosed = () => {
  };

  const handleKeyDown = (e) => {
    // Allow backspace, copy, paste, and cut
    if (
      e.key === "Backspace" ||
      (e.ctrlKey || e.metaKey) && (e.key === "c" || e.key === "v" || e.key === "x")
    ) {
      return;
    }

    // Prevent any other keydown event
    e.preventDefault();
  };

  const handleChange = (e) => {
    const updatedValue = e.target.value;
    setUrl(updatedValue);
    setPreviewData(null);
    setPreviewError(false);
  };

  const handleFileUpload = (dataFile) => {
    setFilesData(dataFile);
  };

  const handleFileRemove = () => {
    setFilesData([]);
  };

  const handleButtonClick = async () => {
    const filesData = [];
    setLoader(true);
    await Promise.all(files.map(async (file, index) => {
      try {
        // const res = await uploadFileToS3(file, "insightai-document-stores");
        // filesData.push(res.Location);
      } catch (e) {
        console.log("Error while uploading the file", e);
        setUploadingMessage("Error while uploading the file, Please try again");
        setLoader(false);
      }
    }));

    if (filesData && filesData.length) {
      // uploadDocument({
      //   files: filesData,
      //   vendor: "documentBased"
      // });

      // const channel = pusher.subscribe(localStorage.getItem("company_slug"));
      // channel.bind("upload_document", (data) => {
      //   setUploadingMessage(data.toString());
      //   // Handle new chat message
      //   if (data.toString() === "Document uploaded!" || data.toString() === "Failed to upload the document!") {
      //     setLoader(false);
      //     setUploadingMessage(data.toString());
      //     channel.unbind("upload_document");
      //     pusher.unsubscribe(localStorage.getItem("company_slug"));
      //     enableQuerySearchOption(true);
      //   }
      // });
    }
  };

  return (<Sidebar
    size="lg"
    open={open}
    title="Upload Your Document"
    headerClassName="mb-2"
    contentClassName="pt-2"
    toggleSidebar={toggleSidebar}
    onClosed={handleSidebarClosed}
    loader={loader}
  >
    <div style={{ width: "100%", height: "68%" }}>
      {
        showDiv1 ? <>
          <span><img src={BotEllipse} alt={""} /></span>
          <div style={{
            background: "#ffffff",
            boxShadow: "0px 1px 4px 1px #00000014",
            width: "60%",
            height: "12%",
            marginLeft: "9%",
            padding: "1.5%"
          }}>Hello {userName}, Welcome to the <b>InsightAI</b>
          </div>
        </> : <> </>
      }

      {
        showDiv2 ? <div style={{
          background: "#ffffff",
          width: "60%",
          height: "19%",
          marginLeft: "9%",
          boxShadow: "0px 1px 4px 1px #00000014",
          padding: "1.5%",
          marginTop: "2.2%"
        }}><p>To get started you need
          to upload your documents. It
          could be <b>PDF</b>, <b>Excel</b>, <b>CSV</b> document or it could be your <b>youtube
            video</b>, <b>Blog</b>, <b>Website URL etc</b></p></div> : <></>
      }

      {
        showDiv3 ? <div style={{
          background: "#ffffff",
          width: "60%",
          height: "12%",
          marginLeft: "9%",
          padding: "1.5%",
          boxShadow: "0px 1px 4px 1px #00000014",
          marginTop: "2.2%"
        }}> Paste url in the chat box
          to upload your <b>Website</b>, <b>Youtube Video</b> data.
        </div> : <> </>
      }

      <Form>
        {/* {
          !querySearchEnabled && showDiv4 && !loader ?
            <div style={{ height: "12%", marginTop: "1.5%", width: "88%", padding: "1.5%", marginLeft: "8%" }}>
              <FileUploaderSingle disabled={loader} handleFileUpload={handleFileUpload}
                                  handleFileRemove={handleFileRemove}
                                  for={"Document"} />
            </div> : <> </>
        } */}

        {
          loading ? <div style={{ marginTop: "1.5%", marginLeft: "8%" }}><p>Loading...</p></div> : previewError ?
            <div style={{ marginTop: "1.5%", marginLeft: "8%" }}><p>Failed to fetch link preview.</p></div> : <></>
        }

        {
          previewData && previewData.videoId ? <div onClick={handleClick} style={{
            width: "87%",
            textAlign: "justify",
            cursor: "pointer",
            margin: "2.2% 0 0 8%",
            background: "#F1F3F5",
            borderRadius: "8px",
            padding: "1.5%",
            fontSize: "small"
          }}>
            <img width={200} height={135} src={previewData.videoThumbnail} alt="Video Thumbnail" />
          </div> : <></>
        }

        {
          previewData && !loading && !previewError ? <div onClick={handleClick} style={{
            width: "87%",
            textAlign: "justify",
            cursor: "pointer",
            margin: "2.2% 0 0 8%",
            background: "#F1F3F5",
            borderRadius: "8px",
            padding: "1.5%",
            fontSize: "small"
          }}>
            <h4>{previewData.title}</h4>
            <p>{previewData.description}</p>
            {previewData.image && <img width={200} height={135} src={previewData.image} alt="Link Preview" />}
          </div> : <> </>
        }

        {
          loader ? <div className="loaderContainer">
            <div className="loader" style={{ textAlign: "center", marginTop: "20%" }}>
              <Spinner color="primary" />
              <div className="postLoadText">Processing...</div>
            </div>
            <div style={{ textAlign: "center", marginTop: "10%", fontSize: "medium", fontWeight: "bold" }}>
              {uploaderText}
            </div>
          </div> : <></>
        }

        {
          querySearchEnabled ? <Button color="success" style={{
            borderRadius: "12px",
            marginLeft: "4%",
            textAlign: "center",
            marginTop: "15%"
          }} tag={Link} to="/chat-bot" block outline className="succecc-btn mb-75">Search
            Document</Button> : <></>
        }

        {
          !querySearchEnabled && showDiv5 ?
            <div style={{ position: "relative", bottom: "4%", height: "8%", width: "95%" }}>
              <Input value={url} placeholder={"Paste Url of your website or youtube video"} disabled={!!files.length}
                     onPaste={handleInputChangePaste}
                     onKeyDown={handleKeyDown} onChange={handleChange}
                     style={{ position: "fixed", bottom: "4%", height: "7%", width: "96%", padding: "1%" }} />
              <Button disabled={loader} onClick={handleButtonClick}
                      style={{ position: "fixed", bottom: "5%", right: "4%" }} color={"primary"}>Send</Button>
            </div> : <></>
        }
      </Form>
    </div>

  </Sidebar>);
};

const mapDispatchToProps = {
  // uploadDocument
};

const mapStateToProps = ({ document }) => {
  return {
    botScript: document.botScript,
    botData: document.botData
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(SidebarDashboard);
