import { useState, useEffect } from "react";
import { Form, FormGroup, Label, Input, FormText, Button, ButtonGroup, Spinner, option } from "reactstrap";
import Script from "./Script";
import { connect } from 'react-redux';
import { chatBotAction } from '../../../../redux/actions/chatBot';
import { FaComment, FaSearch, FaPaperclip, FaMicrophone } from "react-icons/fa";
import Botimg from '../../../../assets/images/bot/darkbot@2x.png';
import { useNavigate } from "react-router";
import './style.scss';
import { fetchBotById } from "../../../../services/chatBot";

import { createChatBot } from "../../../../services/chatBot";
import { statusCode } from "../../../../utility/constants/utilObject";
import { showErrorToast, showSuccessToast} from "../../../../utility/helper";



const ProfileForm = ({
    // chatBotAction,
    // loading,
    error,
    botScript,
    chatbotId,
    botType = 'WEBSITE'
}) => {

    const [formData, setFormData] = useState({
        name: "",
        logo_url: "",
        background: "#0C6980",
        bot_type: "WEBSITE",
        bot_purpose: "WEBSITE",
    });

    const [logoPreview, setLogoPreview] = useState(null);
    const [isLogoUploaded, setIsLogoUploaded] = useState(false);
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [oldScript, setOldScript] = useState("");
    const [script, setScript] = useState('');
    const [showUploadPannel, setShowUploadPannel] = useState(false);
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "logo_url") {
            const file = files[0];
            if (file) {
                if (file.size > 512 * 1024) {
                    setErrors({
                        ...errors,
                        logo_url: "Logo must not exceed 512 KB."
                    });
                    setIsLogoUploaded(false);
                    setLogoPreview(null);
                    return;
                }

                const img = new Image();
                img.onload = () => {
                    if (img.width !== img.height) {
                        setErrors({
                            ...errors,
                            logo_url: "Logo must be a square image."
                        });
                        setIsLogoUploaded(false);
                        setLogoPreview(null);
                    } else {
                        setFormData({
                            ...formData,
                            [name]: file
                        });
                        setLogoPreview(URL.createObjectURL(file));
                        setIsLogoUploaded(true);
                        setErrors({
                            ...errors,
                            logo_url: null
                        });
                    }
                };
                img.onerror = () => {
                    setErrors({
                        ...errors,
                        logo_url: "Invalid image file."
                    });
                };
                img.src = URL.createObjectURL(file);
            }
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }
    };

    const handleResetLogo = () => {
        setFormData({
            ...formData,
            logo_url: ""
        });
        setLogoPreview(null);
        setIsLogoUploaded(false);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.name) {
            newErrors.name = "Name is required.";
        } else if (formData.name.length > 60) {
            newErrors.name = "Name should not exceed 60 characters.";
        }

        if (!formData.logo_url) {
            newErrors.logo_url = "Logo is required.";
        }

        if (!formData.background) {
            newErrors.background = "Background Color is required.";
        }
        if (!formData.bot_type) {
            newErrors.bot_type = "Bot type is required.";
        }
        if (!formData.bot_purpose) {
            newErrors.bot_purpose = "Bot Purpose is required.";
        }


        setErrors(newErrors);

        return Object.keys(newErrors).length === 0;
    };


    const handleFormSubmit = (e) => {
        e.preventDefault();

        if (validateForm()) {
            const formDataObj = new FormData();
            formDataObj.append('name', formData.name);
            formDataObj.append('background', formData.background);
            formDataObj.append('bot_type', formData.bot_type);
            formDataObj.append('logo_url', formData.logo_url);
            formDataObj.append('bot_purpose', formData.bot_purpose);

            setIsLoading(true);
            createChatBot(formDataObj)
                .then((response) => {
                    if (response.status == statusCode.HTTP_201_CREATED) {
                        showSuccessToast('Bot created successfully')
                        // setScript(response?.data?.data?.script);
                        navigate(`/chatbot-dashboard/${response?.data?.data?.id}`);
                        setIsLoading(false);
                    }
                }).catch(error => {
                    // setErrors(error);
                    setIsLoading(false);
                    showErrorToast('Failed to create Bot');
                })
        }
    };

    //Fetching DATA As per ID

    useEffect(() => {
        if (chatbotId != 'new-bot') {

            const fetchBot = async () => {
                try {
                    const response = await fetchBotById(chatbotId);
                    if (response?.data?.data) {
                        setFormData({
                            ...formData,
                            name: response?.data?.data?.name,
                            background: response?.data?.data?.background,
                            logo_url: response?.data?.data?.logo_url,
                            bot_type: response?.data?.data?.bot_type,
                            bot_purpose: response?.data?.data?.bot_purpose,

                        });
                        setOldScript(response?.data?.data?.script)
                        setLogoPreview(response?.data?.data.logo_url);
                        setIsLogoUploaded(true);
                    }

                } catch (error) {
                    setErrors(error);
                } finally {
                    setIsLoading(false);
                }
            }

            fetchBot()
        }
    }, [chatbotId])



    return (
        <div style={{ height: '70vh' }} className="profileform ">
            {/* {Object.keys(botScript).length > 0 &&
                <div className="upload-wrapper">
                    <UploadPannel />
                </div>
            } */}

            <div className="form-wrapper" style={{ width: '50%' }}>
                <Form>
                    <FormGroup className="form-group">
                        <Label for="botName">Name :</Label>
                        <Input
                            id="botName"
                            name="name"
                            placeholder="Enter Your Bot Name"
                            value={formData.name}
                            onChange={handleChange}
                            maxLength="60"
                        />
                        {errors.name && <FormText color="danger">{errors.name}</FormText>}
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label for="botLogo">Logo :</Label>
                        {!isLogoUploaded && (
                            <Input
                                id="botLogo"
                                name="logo_url"
                                type="file"
                                accept="image/*"
                                onChange={handleChange}
                            />
                        )}
                        {logoPreview && (
                            <div>
                                <img src={logoPreview} alt="Logo Preview" style={{ width: '64px', height: '64px', marginTop: '0', marginRight: '20px' }} />
                                <Button color="danger" onClick={handleResetLogo} style={{ marginLeft: '10px' }}>Reset</Button>
                            </div>
                        )}
                        {errors.logo_url && <FormText color="danger">{errors.logo_url}</FormText>}
                        {!isLogoUploaded &&
                            <FormText>
                                Upload your logo. Only square images up to 512 KB can be uploaded.
                            </FormText>
                        }
                    </FormGroup>
                    <FormGroup className="form-group color-picker">
                        <Label for="botColor">Background Color :</Label>
                        <Input
                            id="botColor"
                            name="background"
                            placeholder="color placeholder"
                            type="color"
                            value={formData.background}
                            onChange={handleChange}
                            style={{
                                width: '150px',
                                padding: '0px',
                                border: '0px',
                                height: '25px',
                                borderRadius: '10px'
                            }}
                        />
                        <FormText style={{ marginTop: '0px' }}>
                            Click to change the background color
                        </FormText>
                        {errors.background && <FormText color="danger">{errors.background}</FormText>}
                    </FormGroup>
                    <FormGroup className="form-group">
                        <Label for="botPurpose">Bot Purpose :</Label>
                        <Input
                            id="bot-purpose"
                            name="bot_purpose"
                            type="select"
                            value={formData.bot_purpose}
                            onChange={handleChange}
                        >
                            <option value="WEBSITE">
                                Website Landing Page
                            </option>
                            <option value="PRODUCT">
                                Product Recommendation
                            </option>
                            <option value="SERVICE">
                                Customer Service
                            </option>
                        </Input>
                        {errors.bot_purpose && <FormText color="danger">{errors.bot_purpose}</FormText>}
                    </FormGroup>
                    <Button color="primary" className="" onClick={handleFormSubmit} disabled={isLoading}>
                        {isLoading ?
                            <>
                                <span style={{ marginRight: '5px' }}>Creating your Bot...</span> <Spinner size={"sm"} color="#f1f1f1" />
                            </> : 'Create Website Bot'}
                    </Button>
                </Form>
                {(script || oldScript) && <Script script={script || oldScript || ''} />}
            </div>
            <div className="bot-preview">
                <div className="bot-chat">
                    {/* <img src={logoPreview ? logoPreview : Botimg} style={{ width: '64px', height: '64px', marginTop: '0' }} alt="/"/> */}
                    <div className="bot-chat__top" style={{ backgroundColor: formData.background }}>
                        <span className="comment"><FaComment /> Chat</span>
                        <span className="help"><FaSearch />HelpDesk</span>
                    </div>
                    <div className="bot-chat__body">
                        <div className="user-msg"><span style={{ color: '#fff', backgroundColor: formData.background }}>Hey Bot</span></div>
                        <div className="bot-msg">
                            <span className="bot-msg__logo" style={{ display: 'inline-block', marginRight: '8px' }}>
                                <img src={logoPreview || Botimg} alt="Logo Preview" style={{ width: '30px', height: '30px' }} />
                            </span>
                            <span className="bot-msg__msg" style={{ display: 'inline-block', borderColor: formData.background }}>Hello! How can I assist you today?</span>
                        </div>
                    </div>
                    <div className="bot-chat__bottom">
                        <span className="input">So, what can I help you with?</span>
                        <div className="bot-chat__bottom__attach">
                            <span className="clip"> <FaPaperclip /> <FaMicrophone /></span>
                            <span className="powered-by">Powered By <img src={Botimg} alt="/" /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
// const mapDispatchToProps = {
//     chatBotAction,
// };

// const mapStateToProps = ({ chatBotReducer }) => {
//     return {
//         botScript: chatBotReducer.botScript,
//         loading: chatBotReducer.loading,
//         error: chatBotReducer.error
//     };
// };

export default ProfileForm;

// export default connect(mapStateToProps, mapDispatchToProps)(ProfileForm);
