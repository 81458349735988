import React, { useState, useEffect } from "react";
import "./style.scss";
import { useNavigate } from "react-router-dom";
import {   
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button,
    Card,
} from 'reactstrap';
import { FaTelegram, FaWhatsapp, FaGlobe } from "react-icons/fa";
import { listAllChatBotHandler } from "../../services/chatBot";
import PageHeader from "../../@core/components/PageHeader";
import CustomeTable from "../components/CustomTable";
import { showErrorToast, showSuccessToast } from "../../utility/helper";
import { statusCode } from "../../utility/constants/utilObject";
import { set } from "lodash";
import { X } from "react-feather";

import Banner from "../components/banner";

const ChatBot = () => {
    const navigate = useNavigate()

    // Data loaders
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const allowedColumns = ['serialNo', 'name', 'created_at'];
    const handleNewBot = () => { setShowModal(true) }
    const [showModal, setShowModal] = useState(false);

    const handleWebBot = () => {
        navigate('/chatbot-dashboard/new-bot');
        setShowModal(false);
    }

    useEffect(() => {
        fetchAllUserBots();
    }, []);

    const renderBotModal = () => {
        if (showModal) {
            return (
                <Modal style={{ zIndex: '100' }} isOpen={showModal} className="bot-modal">
                    <ModalHeader>Select Bot Type
                        {/* <Button color="link"><X /></Button> */}
                    </ModalHeader>

                    <ModalBody>
                        <button className="bot-btn web" onClick={handleWebBot}><div className="bot-btn__wrapper"><FaGlobe /> Website Bot</div></button>
                        <button className="bot-btn wtsapp" disabled><div className="bot-btn__wrapper"><FaWhatsapp /> WhatsApp Bot</div></button>
                        <button className="bot-btn tele" disabled><div className="bot-btn__wrapper"><FaTelegram /> Telegram Bot</div></button>

                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={() => setShowModal(false)}>Close</Button>
                    </ModalFooter>
                </Modal>
            )
        }
    }

    const fetchAllUserBots = () => {
        setLoading(true);
        listAllChatBotHandler()
        .then((response) => {
            setLoading(false);
            if(response.status == statusCode.HTTP_200_OK){
                setData(response.data.data);
                showSuccessToast("Loaded all Bots successfully.")
            }else{
                setData([]);
                showErrorToast("Failed to load Bots.")
            }
        })
        .catch((error) => {
            setLoading(false);
            setData([]);
            showErrorToast("Failed to load Bots.")
        })
        
    }

    return (
        <div>
            <PageHeader
                pageName={"ChatBot Dashboard"}
                backButtonEnabled={false}
            />
            <Banner />
            <Card className="chatBotContainer">
                <div className='vh-85 padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: '50px',
                        width: '100%',
                        marginBottom: '15px'
                    }}>
                        <h3>Live Bots</h3>
                        <Button color="primary" onClick={handleNewBot}> Create New Bot</Button>
                    </div>

                    <CustomeTable
                        data={data}
                        loading={loading}
                        allowedColumns={allowedColumns}
                        singleRedirectionLink='/chatbot-dashboard/'
                        errResponse='No Bot found.'
                        loadingText='Loading your Bots'
                        redirectionLink="/chatbot-dashboard/new-bot"
                        redirectTitle="Click here to create a new Bot."
                    />
                </div>

            </Card>
            {renderBotModal()}
        </div>
    )
}

export default ChatBot;