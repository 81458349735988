import classNames from "classnames";

//react
import { useState } from "react";
//reactstrap
import { Table } from 'reactstrap';

//style
import './style.scss';

const AnalysisReport = () => {

    const [assets, setAssets] = useState([
        { category: 'Cash', averageBalance: 10000, interest: 500, rate: 0.05 },
        { category: 'Accounts Receivable', averageBalance: 5000, interest: 200, rate: 0.03 },
    ]);

    const [liabilities, setLiabilities] = useState([
        { category: 'Accounts Payable', averageBalance: 3000, interest: 100, rate: 0.02 },
        { category: 'Loans', averageBalance: 8000, interest: 400, rate: 0.04 },
    ]);

    const calculateTotal = (items, key) => {
        return items.reduce((total, item) => total + item[key], 0);
    };

    const totalAssets = calculateTotal(assets, 'averageBalance');
    const totalLiabilities = calculateTotal(liabilities, 'averageBalance');

    return (
        <div className="analysis-report">

            {/* <div className="my-2">
                <h2 className="h2 text-center">Company Valuation Report</h2>
                <p className="text-center">(For the period of April 2023 - March 2024)</p>
            </div> */}

            <div className="analysis-report__table-wrapper">
                <Table borderless className="balance-sheet-table">
                    <thead>
                        <tr>
                            <th>
                                Category
                            </th>
                            <th>
                                Average Balance
                            </th>
                            <th>
                                Interest Income / Expenses
                            </th>
                            <th>
                                Rate
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr className="category-title-row">
                            <th className="category-title">
                                Assets
                            </th>
                        </tr>
                        {assets.map((asset, index) => (
                            <tr key={index}>
                                <td>{asset.category}</td>
                                <td>{asset.averageBalance}</td>
                                <td>{asset.interest}</td>
                                <td>{asset.rate * 100}%</td>
                            </tr>
                        ))}
                        <tr className="total-row">
                            <th>
                                Total Assets
                            </th>
                            <td>{totalAssets}</td>
                            <td>{calculateTotal(assets, 'interest')}</td>
                        </tr>

                        {/* Liabilities */}
                        <tr className="category-title-row">
                            <th className="category-title">
                                Liabilities
                            </th>
                        </tr>

                        {liabilities.map((liabiity, index) => (
                            <tr key={index}>
                                <td>{liabiity.category}</td>
                                <td>{liabiity.averageBalance}</td>
                                <td>{liabiity.interest}</td>
                                <td>{liabiity.rate * 100}%</td>
                            </tr>
                        ))}
                        <tr className="total-row">
                            <th>
                                Total Liabilities
                            </th>
                            <td>{totalLiabilities}</td>
                            <td>{calculateTotal(liabilities, 'interest')}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>

        </div>
    )
}

export default AnalysisReport;