import './style.scss';
import { useState, useEffect } from "react";
import { useParams, Link, useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Card, Nav, NavItem, NavLink, TabContent, TabPane, Tooltip } from "reactstrap";
import PageHeader from "../../../@core/components/PageHeader";
import ProfileForm from './ProfileForm';
import ChatBotAnalytics from '../ChatBotAnalytics';
import ChatBotConversation from './ChatbotConversation';


const ChatbotProfile = ({ botScript }) => {
    const { bot_id, bot_tab } = useParams();
    const [activeTab, setActiveTab] = useState('1');
    const [chatbotId, setChatbotId] = useState(bot_id);
    const [isBotCreated, SetIsBotCreated] = useState(true);

    useEffect(() => {
        if (bot_id != 'new-bot') {
            setChatbotId(bot_id);
            //this is checking, bot created or not
            SetIsBotCreated(false);
        }
    }, [bot_id]);

    //For Active Tab

    useEffect(() => {
        if (bot_tab) {
            setActiveTab(bot_tab);
        }
    }, [bot_tab]);

    // Function to toggle active tab
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <div>
            <PageHeader
                pageName={"ChatBot Proflie"}
                backButtonEnabled={false}
            />
            <Card>
                <div className='vh-85 padding_0' style={{ padding: '15px', boxSizing: 'border-box' }}>
                    <Nav tabs className="chatBot-profile__tabs">
                        <NavItem>
                            <NavLink
                                tag={Link}
                                to={`/chatbot-dashboard/${chatbotId}/1`}
                                className={activeTab === '1' ? 'active' : ''}
                                onClick={() => toggleTab('1')}
                            >
                                Chatbot Profile
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                tag={Link}
                                to={`/chatbot-dashboard/${chatbotId}/2`}

                                className={activeTab === '2' ? 'active' : ''}
                                onClick={() => toggleTab('2')}
                                disabled={isBotCreated}
                            >
                                Chatbot Knowledge Base
                            </NavLink>

                        </NavItem>
                        {/* <NavItem>
                            <NavLink
                                tag={Link}
                                to={`/chatbot-dashboard/${chatbotId}/3`}
                                className={activeTab === '3' ? 'active' : ''}
                                onClick={() => toggleTab('3')}
                                disabled={isBotCreated}

                            >
                                Chatbot Analytics
                            </NavLink>
                        </NavItem> */}
                    </Nav>
                    <TabContent activeTab={activeTab} className="chatBot-profile__tabcontent">
                        <TabPane tabId="1" className="chatBot-profile__tabpane">
                            <ProfileForm botType="WEBSITE" chatbotId={chatbotId} />
                        </TabPane>
                        <TabPane tabId="2" className="chatBot-profile__tabpane">
                            <ChatBotConversation chatbotId={chatbotId} />
                        </TabPane>
                        <TabPane tabId="3" className="chatBot-profile__tabpane">
                            <ChatBotAnalytics chatbotId={chatbotId} />
                        </TabPane>
                    </TabContent>
                </div>
            </Card>
        </div>
    )
}

const mapStateToProps = ({ chatBotReducer }) => {
    return {
        botScript: chatBotReducer.botScript,
        loading: chatBotReducer.loading,
        error: chatBotReducer.error
    };
};


export default connect(mapStateToProps, null)(ChatbotProfile);