export const NAVIGATION_ROUTE_KEYS = {
    BLOG_GENERATION: '/generator', 
    LINKEDIN_POST_GENERATION: '/linkedin-blog-generate',
    CUSTOM_BLOG_GENERATION: '/custom-blog-generate',
    SHORT_FORM_GENERATION: '/short-form-content',
    POINTS_TO_CONTENT: '/point-to-content',
    INSTAGRAM: '/instagram-post',
    BUSINESS_EMAIL:'/business-emails',
    UPLOAD_DOCUMENT_CONTENT: '/upload-document-content',
    PARAGRAPH_N_N: '/paragraph-n-n',
    PARAGRAPH_N_1: '/paragraph-n-1',
    PARAGRAPH_TO_POINTS: '/para-to-points',
    SAME_PAGE:'#',
    BANK_STATEMENT_PARSER: '/financial-statement-analyser',
    CASE_INVESTIGATION: '/case-investigation',
    ANTI_MONEY_LAUNDERING: '/anti-money-laundering',
    COMPANY_VALUATION_REPORT: '/company-valuation-report',
}
