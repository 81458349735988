import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// axios

import axios from 'axios';


//ReactStrap

import { FormGroup, Input, ListGroup, ListGroupItem, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';

//icons
import { FaSearch } from 'react-icons/fa';

//classnames
import classNames from 'classnames';
//country flags
import CountryFlag from 'react-country-flag';

//Upload Documents
import UploadStatement from './UploadSatement';
import StatementProcess from './StatementProcess';
import { getBaseUrl } from '../../../utility/Utils';
import { bankListRequest } from '../../../services/bankStatement';



const Banks = ({ showBanks, handleBank }) => {
    const [mainBanks, setMainBanks] = useState([]);
    const [otherBanks, setOtherBanks] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredData, setFilteredData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    // const [bankSelection, setBankSelection] = useState(showBanks);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showStatementProcess, setShowStatementProcess] = useState(false);
    const [handleFile, setHandleFile] = useState([]);
    // const [selectCountry, setSelectCountry] = useState('India')
    const [selectedCountry, setSelectedCountry] = useState({
        name: "India",
        code: "IN"
    });
    //loader and error
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);


    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const selectItem = (name, code) => {
        setSelectedCountry({ name, code });
        setDropdownOpen(false);
    };

    const handleItemClick = (item) => {
        if (item) {
            setSelectedItem(item);
            setShowPopup(prev => !prev);
            handleBank(item);
        }
    };


    // const handleCloseComponent = () => {
    //     setSelectedItem(null);
    // };


    const handleSearch = (query) => {
        const filteredResults = otherBanks.filter(item => {
            return item.name.toLowerCase().includes(query.toLowerCase());
        });
        setFilteredData(filteredResults);
        setSearchQuery(query);
    };


    useEffect(() => {
        setLoading(true);
        const fetchBankList = async () => {
            try {
                const response = await bankListRequest(selectedCountry.name);
                setMainBanks(response?.data?.data?.MAIN_BANK || []);
                setOtherBanks(response?.data?.data?.OTHER_BANK || []);
            } catch (err) {
                setError(err);
            } finally {
                setLoading(false);
            }
        };
        fetchBankList();
    }, [selectedCountry]);

    useEffect(() => {
        setFilteredData(otherBanks);
    }, [otherBanks]);

    const changeComponent = (file) => {
        setShowStatementProcess(prev => !prev);
        setHandleFile(file);

    }

    if (showStatementProcess) {
        return (
            <StatementProcess selectedItem={selectedItem} files={handleFile} />
        )
    }


    return (
        <div className="banks-selection">
            <div className="banks-selection__inner" banks-selection>
                <div className='banks-selection__top'>
                    <h2 className='mb-2 '>Select Your Bank</h2>
                    <div className='banks-selection__dropdown'>
                        <h2 className='dropdown-label'>Select Country</h2>

                        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
                            <DropdownToggle caret color="primary" outline>
                                <CountryFlag countryCode={selectedCountry.code} svg />
                                <span className='country-name'>{selectedCountry.name}</span>
                            </DropdownToggle>
                            <DropdownMenu container="body">
                                <DropdownItem onClick={() => { selectItem('India', "IN") }}>
                                    <CountryFlag countryCode={"IN"} svg />
                                    <span className='contry-name'>India</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => { selectItem('United States', "US") }}>
                                    <CountryFlag countryCode={"US"} svg />
                                    <span className='contry-name'>United States</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => { selectItem('United Kingdom', "GB") }}>
                                    <CountryFlag countryCode={"GB"} svg />
                                    <span className='contry-name'>United Kingdom</span>
                                </DropdownItem>
                                <DropdownItem onClick={() => { selectItem('Uae', "AE") }}>
                                    <CountryFlag countryCode={"AE"} svg />
                                    <span className='contry-name'>UAE</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>

                {
                    loading ?
                        <div className='loader-wrapper'>
                            <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#7169E8" data-testid="oval-svg">
                                <g fill="none" fillRule="evenodd">
                                    <g transform="translate(1 1)" strokeWidth="2" data-testid="oval-secondary-group">
                                        <circle strokeOpacity=".5" cx="0" cy="0" r="20" stroke="#7169e896" strokeWidth="2"></circle>
                                        <path d="M20 0c0-9.94-8.06-20-20-20">
                                            <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
                                        </path>
                                    </g>
                                </g>
                            </svg>

                        </div>
                        : error || mainBanks.length === 0 ?
                            <h2 className='error-msg'>No data Found</h2>
                            :
                            <>
                                <div className="main-banks">
                                    {
                                        mainBanks.map((item, i) => {
                                            return (
                                                <Link to='' className='main-banks__tile' key={item?.code || ''} onClick={() => handleItemClick(item)}>
                                                    <img src={item?.image_url || ''} alt='sbi' />
                                                    <span className='bank-name'>{item.name}</span>
                                                </Link>
                                            )
                                        })
                                    }
                                </div>
                                <div className='search-bank my-2'>
                                    <FormGroup>
                                        <span className="input-icon">
                                            <FaSearch />
                                        </span>
                                        <Input
                                            id="bankSearch"
                                            name="search"
                                            placeholder="Search Bank"
                                            type="search"
                                            value={searchQuery}
                                            onChange={(e) => handleSearch(e.target.value)}
                                        />
                                    </FormGroup>
                                    <h2 className='h2 my-2'>Other Banks</h2>

                                    <ListGroup className='other-bank-list'>
                                        {filteredData.map((item, index) => {
                                            return (

                                                <ListGroupItem
                                                    key={index}
                                                    className='list-item'
                                                    onClick={() => handleItemClick(item)}
                                                >
                                                    <span className='bank-icon'>
                                                        <img src={item?.image_url || ''} alt={item.name} />

                                                    </span>
                                                    {item.name}
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M438.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-160-160c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L338.8 224 32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l306.7 0L233.4 393.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l160-160z" /></svg>
                                                </ListGroupItem>

                                            )

                                        })}
                                    </ListGroup>
                                </div>
                            </>
                }
            </div>
            {/* </div> */}

            <UploadStatement
                selectedItem={selectedItem}
                showPopup={showPopup}
                toggle={handleItemClick}
                handleChange={changeComponent}
            // showStatementProcess={showStatementProcess}
            />
        </div>
    )

}

export default Banks;