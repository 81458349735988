import { NAVIGATION_ROUTE_KEYS } from "../../../utility/constants/navKeys";

import RevaluationImg from '../../../assets/images/logo/revaluation.png'
import SmsImg from '../../../assets/images/logo/sms.png'

const NewTemplates = [
  {
    image: './assets/images/logo/bank.png',
    heading: "Financial Statement Analyser",
    nav: NAVIGATION_ROUTE_KEYS.BANK_STATEMENT_PARSER,
    contentType: "2",
    subHeading: "Get Bank statement analysis",
  },
  // {
  //   image: './assets/images/logo/case_investigate.png',
  //   heading: "Case investigation report",
  //   contentType: "NONE",
  //   nav: NAVIGATION_ROUTE_KEYS.CASE_INVESTIGATION,
  //   subHeading: "Case investigaion report analysis",
  // },
  // {
  //   image: RevaluationImg,
  //   heading: "Company Valuation Report",
  //   contentType: "NONE",
  //   nav: NAVIGATION_ROUTE_KEYS.COMPANY_VALUATION_REPORT,
  //   subHeading: "Company Valuation report analysis",
  // },
  // {
  //   image: SmsImg,
  //   heading: "Bank SMS Analytics",
  //   contentType: "NONE",
  //   // nav: NAVIGATION_ROUTE_KEYS.CASE_INVESTIGATION,
  //   subHeading: "Bank SMS Analytics report",
  // },
  
  // {
  //   image: '../../../assets/images/logo/kyc.png',
  //   heading: "KYC Api",
  //   // nav: NAVIGATION_ROUTE_KEYS.WHATSAPP,
  //   contentType: "20",
  //   subHeading: "Get KYC auth of customers",
  // },
  // {
  //   image: './assets/images/logo/loan.png',
  //   heading: "Loan origination",
  //   // nav: NAVIGATION_ROUTE_KEYS.MESSAGE,
  //   contentType: "21",
  //   subHeading:
  //     "Loan origination system",  
  // },
  // {
  //   image: './assets/images/logo/facematch.png',
  //   heading: "Live face match",
  //   // nav: NAVIGATION_ROUTE_KEYS.IMAGE_RECC,
  //   contentType: "22",
  //   subHeading:
  //     "Facematch for users with their ID",  
  // },
  // {
  //   image: './assets/images/logo/geospat.png',
  //   heading: "Geo spatial",
  //   contentType: "NONE",
  //   // nav: NAVIGATION_ROUTE_KEYS.PARAGRAPH_N_N,
  //   subHeading: "Geo Spatial verification",
  // },
  // {
  //   image: './assets/images/logo/income.png',
  //   heading: "Income analysis",
  //   contentType: "NONE",
  //   // nav: NAVIGATION_ROUTE_KEYS.PARAGRAPH_N_N,
  //   subHeading: "Income analysis",
  // },
  // {
  //   image: './assets/images/logo/fruad.png',
  //   heading: "Fruad analysis",
  //   contentType: "NONE",
  //   // nav: NAVIGATION_ROUTE_KEYS.PARAGRAPH_N_N,
  //   subHeading: "Fruad detection algorithm on bank statements",
  // },
  
]

export { NewTemplates };
