// ** React Imports
import { Fragment, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

// ** Third Party Components
import "./style.scss";
import "cleave.js/dist/addons/cleave-phone.us";

const { decode } = require("base64-arraybuffer");
import defaultAvatar from "@src/assets/images/avatars/avatar-user.png";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Form,
  Card,
  Input,
  Label,
  Button,
  CardBody,
  CardTitle,
  CardHeader,
  Spinner
} from "reactstrap";

// ** Utils
import {
  isValidPassword,
  onLogout, saveDataToLocalStorage,
  toastStyle
} from "../../../utility/helper";

// ** Demo Components
import DeleteAccount from "./templates/DeleteAccount";
import AvatarEditor from "react-avatar-editor";
import InputPasswordToggle from "../../../@core/components/input-password-toggle";
import toast from "react-hot-toast";
import { updateUserData } from "../../../services/onboarding";
import { statusCode } from "../../../utility/constants/utilObject";

const AccountTabs = () => {
  // ** States
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState(null);
  const [isFileInputEnabled, setIsFileInputEnabled] = useState(true);
  const editorRef = useRef(null);
  const [formData, setFormData] = useState({
    last_name: localStorage.getItem("last_name") || "",
    first_name: localStorage.getItem("first_name") || "",
    email: localStorage.getItem("email") || "",
    mobile: localStorage.getItem("mobile") || "",
    password: "",
    confirmPassword: ""
  });

  const navigate = useNavigate();


  const checkUrl = () => {
    let image_url = localStorage.getItem("image_url");
    if (image_url === "null" || image_url === "NA" || !image_url) {
      image_url = defaultAvatar;
    }
    setImage(image_url);
  };

  useEffect(() => {
    checkUrl();
  }, []);

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const stopLoader = () => {
    setTimeout(() => setLoader(false), 1000);
  }

  const onSubmit = e => {
    setLoader(true);
    e.preventDefault();

    if (!formData.first_name) {

      return toast.error("First Name cannot be empty", { ...toastStyle.error });
    }

    if (!formData.last_name) {
      stopLoader()
      return toast.error("Last Name cannot be empty", { ...toastStyle.error });
    }

    if (formData.mobile && (formData.mobile.length < 10 || formData.mobile.length > 12)) {
      stopLoader();
      return toast.error("Invalid Mobile Number", { ...toastStyle.error });
    }

    if (formData.password && !formData.confirmPassword) {
      stopLoader();
      return toast.error("Confirm password field cannot be empty", { ...toastStyle.error });
    }

    if (formData.password && !isValidPassword(formData.password)) {
      stopLoader();
      return toast.error("Password validation failed", { ...toastStyle.error });
    }

    if (formData.confirmPassword && !formData.password) {
      stopLoader();
      return toast.error("Password field cannot be empty", { ...toastStyle.error });
    }

    if (formData.password && formData.confirmPassword
      && formData.password.trim().toLowerCase() !== formData.confirmPassword.trim().toLowerCase()) {
      stopLoader();
      return toast.error("Passwords does not match", { ...toastStyle.error });
    }

    if (editorRef.current && image) {
      const canvas = editorRef.current.getImageScaledToCanvas();
      formData.image_url = canvas.toDataURL("image/png");
      setIsFileInputEnabled(true); // Enable the file input after image upload
    }

    // API Call
    updateUserData(formData, localStorage.getItem("id")).then(res => {
      if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
        saveDataToLocalStorage(res.data.data);
        toast("User Details has been updated", { ...toastStyle.success });
        stopLoader();
        navigate('/dashboard');
      } else {
        stopLoader();
        return toast.error(
          `${res.response && res.response.data && res.response.data.error ? res.response.data.error : res.response && res.response.data && res.response.data[Object.keys(res.response.data)[0]] || "Something went wrong"}`, { ...toastStyle.error }
        );
      }
    }).catch(err => {
      stopLoader();
      return toast.error("Something went wrong, Please contact support !", { ...toastStyle.error });
    });

  };

  const logoutUser = () => {
    onLogout();
    navigate("/");
  };


  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setIsFileInputEnabled(false); // Disable the file input after image selection
    }
  };

  const handleImageReset = () => {
    checkUrl();
    setIsFileInputEnabled(true); // Enable the file input after resetting
  };

  const handleCircularClick = () => {
    if (isFileInputEnabled) {
      document.getElementById("imageInput").click();
    }
  };

  return (
      <div style={{ maxHeight: "600px", overflowY: "auto" }}>
        <Card>
          <CardHeader style={{ display: "flex", justifyContent: "space-between", padding: "1%" }}
                      className="border-bottom">
            <CardTitle tag="h3"><b>Profile Details</b></CardTitle>
            <div>
              <Button onClick={logoutUser} className="me-1" color="danger">
                Logout
              </Button>
            </div>
          </CardHeader>
          <CardBody className="py-2 my-25">
            <div className="d-flex" style={{ marginTop: "-0.5%", marginBottom: "-0.5%" }}>
              <div
                className="circular-image"
                onClick={handleCircularClick}
              >
                <AvatarEditor
                  ref={editorRef}
                  image={image}
                  width={110}
                  height={110}
                  border={10}
                  color={[255, 255, 255, 0.6]}
                  scale={1.1}
                  rotate={0.3}
                  borderRadius={200}
                  style={{ cursor: isFileInputEnabled ? "pointer" : "default" }}
                /> : <> </>
              </div>

              <div>

                <input
                  id="imageInput"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleImageChange}
                />

                <div className="action-buttons">
                  <button onClick={handleImageReset} className="button-reset">Reset</button>
                </div>
              </div>
            </div>
            <Form className="mt-2 pt-30" onSubmit={onSubmit}>
              <Row>
                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="first_name">
                    First Name
                  </Label>
                  <Input
                    type="text"
                    name="first_name"
                    disabled={loader}
                    placeholder="First Name"
                    onChange={handleChange}
                    value={formData.first_name}
                  />
                </Col>
                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="last_name">
                    Last Name
                  </Label>
                  <Input
                    type="text"
                    name="last_name"
                    disabled={loader}
                    placeholder="Last Name"
                    onChange={handleChange}
                    value={formData.last_name}
                  />
                </Col>
                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="email">
                    Email
                  </Label>
                  <Input
                    type="email"
                    name="email"
                    disabled={true}
                    placeholder="Email"
                    onChange={handleChange}
                    value={formData.email}
                  />
                </Col>
                {/* <Col sm='6' className='mb-1'>
                <Label className='form-label' for='company'>
                  Company
                </Label>
                <Input defaultValue={data.company} id='company' name='company' placeholder='Company Name' />
              </Col> */}
                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="mobile">
                    Mobile
                  </Label>
                  <Input
                    name="mobile"
                    type="number"
                    disabled={loader}
                    placeholder=""
                    onChange={handleChange}
                    value={formData.mobile}
                  />
                </Col>
                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="password">
                    Password
                  </Label>
                  <InputPasswordToggle
                    name="password"
                    htmlFor="password"
                    disabled={loader}
                    className="input-group-merge"
                    value={formData.password}
                    onChange={handleChange}
                  />
                </Col>
                <Col sm="6" className="mb-1">
                  <Label className="form-label" for="confirmPassword">
                    Confirm Password
                  </Label>
                  <InputPasswordToggle
                    name="confirmPassword"
                    htmlFor="confirmPassword"
                    disabled={loader}
                    className="input-group-merge"
                    value={formData.confirmPassword}
                    onChange={handleChange}
                  />
                </Col>
                <div style={{ opacity: 0.7 }}>
                  <small>Password must have 7+ characters, including at least 1 letter, 1 digit, and 1 special character
                    (e.g., @$!%*?&)
                  </small>
                </div>
                {/* <Col sm='6' className='mb-1'>
                <Label className='form-label' for='address'>
                  Address
                </Label>
                <Input id='address' name='address' placeholder='12, Business Park' />
              </Col>
              <Col sm='6' className='mb-1'>
                <Label className='form-label' for='accountState'>
                  State
                </Label>
                <Input id='accountState' name='state' placeholder='California' />
              </Col>
              <Col sm='6' className='mb-1'>
                <Label className='form-label' for='zipCode'>
                  Zip Code
                </Label>
                <Input id='zipCode' name='zipCode' placeholder='123456' maxLength='6' />
              </Col>
              <Col sm='6' className='mb-1'>
                <Label className='form-label' for='country'>
                  Country
                </Label>
                <Select
                  id='country'
                  isClearable={false}
                  className='react-select'
                  classNamePrefix='select'
                  options={countryOptions}
                  theme={selectThemeColors}
                  defaultValue={countryOptions[0]}
                />
              </Col>
              <Col sm='6' className='mb-1'>
                <Label className='form-label' for='language'>
                  Language
                </Label>
                <Select
                  id='language'
                  isClearable={false}
                  className='react-select'
                  classNamePrefix='select'
                  options={languageOptions}
                  theme={selectThemeColors}
                  defaultValue={languageOptions[0]}
                />
              </Col>
              <Col sm='6' className='mb-1'>
                <Label className='form-label' for='timeZone'>
                  Timezone
                </Label>
                <Select
                  id='timeZone'
                  isClearable={false}
                  className='react-select'
                  classNamePrefix='select'
                  options={timeZoneOptions}
                  theme={selectThemeColors}
                  defaultValue={timeZoneOptions[0]}
                />
              </Col>
              <Col sm='6' className='mb-1'>
                <Label className='form-label' for='currency'>
                  Currency
                </Label>
                <Select
                  id='currency'
                  isClearable={false}
                  className='react-select'
                  classNamePrefix='select'
                  options={currencyOptions}
                  theme={selectThemeColors}
                  defaultValue={currencyOptions[0]}
                />
              </Col> */}
                {loader ? <div className="sipnner-container">
                    <Spinner color="primary" />
                  </div> :
                  <Col className="mt-1" sm="5">
                    <Button type="submit" className="me-1" color="primary">
                      Update
                    </Button>
                  </Col>
                }
              </Row>
            </Form>
          </CardBody>
        </Card>
        {/* <DeleteAccount /> */}
      </div>
  );
};

export default AccountTabs;
