import axios from "axios";
import { WEB_ROUTE, REQUESTS } from "./apiConfig";

const API_ROUTES = {
    verify_email: "auth/verify/verify_email/",
    send_verification_email: "auth/verify/send_verification_email/",
    fetch_all_user: "auth/user/fetch_all_user/",
    fetchThirdPartyAuthPlatform: "auth/third_party_auth/fetch_users_auth/",
    deleteThirdPartyAuthPlatform: "auth/third_party_auth/{0}",
    createContact: "user/query/contact_us/",
    resetPassword: "auth/verify/reset_password/"
}

export const verifyEmailHandler = (token) => {
    return REQUESTS.post_without_token(API_ROUTES.verify_email, { token })
};

export const sendVerifyEmailHandler = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.send_verification_email, payload)
};

export const fetchThirdPartyAuthListHandler = () => {
    return REQUESTS.get(API_ROUTES.fetchThirdPartyAuthPlatform , localStorage.getItem('token'))
};

export const deleteThirdPartyAuthListHandler = (objectId) => {
    return REQUESTS.delete(
        API_ROUTES.deleteThirdPartyAuthPlatform.format(objectId) , 
        localStorage.getItem('token')
    )
};

export const createContact = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.createContact, payload)
}

export const resetPassword = (payload) => {
    return REQUESTS.post_without_token(API_ROUTES.resetPassword, payload)
}
