// ** React Imports
import { Fragment, useState, useEffect } from 'react'

//React Toast
import toast from "react-hot-toast";

// ** Reactstrap Imports
import {
  Row,
  Col,
  Card,
  CardBody,
  Progress,
  CardTitle,
  CardHeader,
  Table
} from 'reactstrap'

// ** Demo Components
import PricingCard from '@src/views/pages/Templated/pricing/PricingCards'

// ** Third Party Components
import axios from 'axios'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

// ** Styles
import '@styles/base/pages/page-pricing.scss'
import '@styles/base/plugins/extensions/ext-component-sweet-alerts.scss'
import '../style.scss'
import { async } from '@firebase/util'

//Baseurl
import { getBaseUrl } from '../../../../utility/Utils'
import { faL } from '@fortawesome/free-solid-svg-icons'
const MySwal = withReactContent(Swal)

//Moment
import moment from 'moment';

//react-loader-spinner
import { Oval } from 'react-loader-spinner';

const BillingCurrentPlan = () => {
  // ** States
  const [show, setShow] = useState(false)
  const [data, setData] = useState(null)
  const [duration, setDuration] = useState('monthly');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [userPlan, setUserPlan] = useState({});
  const [paymentDetails, setPaymentDetails] = useState({});
  const [orderDetails, setOrderDetails] = useState({});
  const [planDetails, setPlanDetails] = useState({});
  const [credits, setCredits] = useState();

  const [daysLeft, setDaysLeft] = useState(0);
  const [currentDay, setCurrentDay] = useState(0);
  const [progressValue, setProgressValue] = useState(0);

  //loader and error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);



  // useEffect(() => {
  //   axios.get('/pricing/data').then(res => {
  //     const dataArr = []

  //     Object.entries(res.data).forEach(([key, val]) => {
  //       if (key !== 'qandA') {
  //         dataArr.push(val)
  //         setData([...dataArr])
  //       }
  //     })
  //   })
  // }, [])
  localStorage.setItem('is_plan_activated', true);

  useEffect(async () => {
    const paymentTransactionId = localStorage.getItem('paymentTransactionId');
    try {
      setLoading(true)
      const response = await axios.get(`${getBaseUrl()}billing/transaction/current_status?merchant_id=${paymentTransactionId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        }
      );

      // console.log(response, 'response')
      if (response?.data?.data) {
        const { user_plan, payment_details, order_details, credits_available, plan_details } = response.data.data;
        setPaymentSuccess(true);

        if (user_plan) {
          //Progress bar calculation
          const today = new Date().getTime();
          const start = new Date(user_plan.start_date).getTime();
          const end = new Date(user_plan.end_date).getTime();

          const totalDays = Math.ceil((end - start) / (1000 * 60 * 60 * 24));
          const remainingDays = Math.ceil((end - today) / (1000 * 60 * 60 * 24));

          setDaysLeft(remainingDays);
          setCurrentDay(totalDays - remainingDays);
          setProgressValue(Math.ceil(((totalDays - remainingDays) / totalDays) * 100));
          //userplan
          setUserPlan(user_plan);
        }

        if (payment_details) {
          setPaymentDetails(payment_details);
        }

        if (order_details) {
          setOrderDetails(order_details);
        }

        if (credits_available) {
          setCredits(credits_available);
          localStorage.setItem('credit_available', credits_available);
          localStorage.setItem('is_plan_activated', true);
        }

        if (plan_details) {
          setPlanDetails(plan_details);
        }
      }
    } catch (err) {
      // toast.error('No payment details found');
      // localStorage.setItem('credit_available', 30000);
      // localStorage.setItem('is_plan_activated', false);
      setError(err)
    } finally {
      setLoading(false)
    }
  }, []);


  const formatDate = (date) => {
    const getdate = new Date(date);
    const day = String(getdate.getDate()).padStart(2, '0');
    const month = String(getdate.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = getdate.getFullYear();
  
    return `${day}-${month}-${year}`;
  }

  const onChange = e => {
    if (e.target.checked) {
      setDuration('yearly')
    } else {
      setDuration('monthly')
    }
  }

  const handleConfirmCancel = () => {
    return MySwal.fire({
      title: '',
      text: 'Are you sure you would like to cancel your subscription?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    }).then(function (result) {
      if (result.value) {
        MySwal.fire({
          icon: 'success',
          title: 'Unsubscribed!',
          text: 'Your subscription cancelled successfully.',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      } else if (result.dismiss === MySwal.DismissReason.cancel) {
        MySwal.fire({
          title: 'Cancelled',
          text: 'Unsubscription Cancelled!!',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-success'
          }
        })
      }
    })
  }
  //Loader
  if (loading) {
    return (
      <div className='loader-wrapper'>
        <svg width="80" height="80" viewBox="-20 -20 42 42" xmlns="http://www.w3.org/2000/svg" stroke="#7169E8" data-testid="oval-svg">
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(1 1)" stroke-width="2" data-testid="oval-secondary-group">
              <circle stroke-opacity=".5" cx="0" cy="0" r="20" stroke="#7169e896" stroke-width="2"></circle>
              <path d="M20 0c0-9.94-8.06-20-20-20">
                <animateTransform attributeName="transform" type="rotate" from="0 0 0" to="360 0 0" dur="1s" repeatCount="indefinite"></animateTransform>
              </path>
            </g>
          </g>
        </svg>

      </div>
    )
  }
  //Error
  if (error) {
    return (
      <div>
        <h1 className='h1 m-auto text-center'>No Record exist</h1>
      </div>
    )
  }
  return (
    <Fragment>
      <Card>
        <CardHeader className='border-bottom'>
          <CardTitle tag='h4'>Current plan</CardTitle>
        </CardHeader>
        <CardBody className='my-2 py-25'>
          <Row>
            <Col md='6'>
              <div className='mb-2 pb-50'>
                <h5>
                  Your Current Plan is <strong>{paymentSuccess ? (planDetails.title || '') : 'Basic'}</strong>
                </h5>
                {paymentSuccess ? <span>{planDetails.subtitle}</span> : <span>A simple start for everyone</span>}
                {paymentSuccess ? <span className='d-block my-1'>{credits} credits available</span> : <span></span>}


              </div>
              {/* <div className='mb-2 pb-50'>
                <h5>Active until June 30, 2023</h5>
                <span>We will send you a notification 10 days before subscription expiration</span>
              </div> */}
              {/* <div className='mb-2 mb-md-1'>
                <h5>
                  $199 Per Month{' '}
                  <Badge color='light-primary' className='ms-50'>
                    Popular
                  </Badge>
                </h5>
                <span>Standard plan for small to medium businesses</span>
              </div> */}
            </Col>
            {
              paymentSuccess ?
                <Col md='6'>
                  {/* <Alert color='warning'>
                <h4 className='alert-heading'>We need your attention!</h4>
                <div className='alert-body'>your plan requires update</div>
              </Alert> */}
                  <div className='plan-statistics pt-1'>
                    <div className='d-flex justify-content-between'>
                      <h5 className='fw-bolder'>Days</h5>
                      <h5 className='fw-bolder'>{currentDay} of {currentDay + daysLeft} Days</h5>
                    </div>
                    <Progress className='mb-50' value={progressValue} />
                    <p className='mt-50'>{daysLeft} days remaining until your plan requires update</p>
                  </div>
                </Col>
                :
                ''
            }

            {/* <Col xs={12}>
              <Button color='primary' className='me-1 mt-1' onClick={() => setShow(true)}>
                Upgrade Plan
              </Button>
              <Button outline color='danger' className='mt-1' onClick={handleConfirmCancel}>
                Cancel Subscription
              </Button>
            </Col> */}
          </Row>
        </CardBody>
      </Card>

      {paymentSuccess ?
        <Card className='transactions-preview-card my-2'>
          {/* User Plan Description */}
          <CardBody className='plan-description pb-4'>
            <h2 className='h2 mb-2'>User Plan Description</h2>
            <Table responsive>
              <tbody>
                <tr>
                  <th>Credits Available</th>
                  <td>{userPlan.credit_available || 'NA'}</td>
                </tr>
                <tr>
                  <th>Start Date</th>
                  <td>{formatDate(userPlan.start_date) || 'NA'}</td>
                </tr>
                <tr>
                  <th>End Date</th>
                  <td>{formatDate(userPlan.end_date) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Updated AT</th>
                  <td>{formatDate(userPlan.updated_at) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Create At</th>
                  <td>{formatDate(userPlan.created_at) || 'NA'}</td>
                </tr>

                <tr>
                  <th>Coupon</th>
                  <td>{userPlan.coupon_code || 'NA'}</td>
                </tr>
                <tr>
                  <th>Pricing</th>
                  <td>{('₹' + ' '+ userPlan.pricing) || 'NA'}</td>
                </tr>
                
                
              </tbody>


            </Table>
          </CardBody>
          <hr className='invoice-spacing' />

          {/* Plan Details */}
          <CardBody className='payment-details pb-4'>
            <h2 className='h2 mb-2'>Plan Details</h2>
            <Table responsive>
              <tbody>
                <tr>
                  <th>Price:</th>
                  <td>₹ {planDetails.amount || 'NA'}</td>
                </tr>
                <tr>
                  <th>Created AT:</th>
                  <td>{formatDate(planDetails.created_at) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Credits Provided:</th>
                  <td>{planDetails?.credit_provided || 'NA'}</td>
                </tr>
                <tr>
                  <th>Currency:</th>
                  <td>{planDetails?.currency || 'NA'}</td>
                </tr>
                <tr>
                  <th>Monthly Price:</th>
                  <td>{planDetails?.monthlyPrice || 'NA'}</td>
                </tr>
                <tr>
                  <th>Period:</th>
                  <td>{(planDetails?.period + 'Days') || 'NA'}</td>
                </tr>
                <tr>
                  <th>Benefits:</th>
                  <td>{(planDetails?.planBenefits.map((items, i)=>{
                    return(
                      <li key={i}>{items}</li>
                    )
                  })) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Plan Type:</th>
                  <td>{(planDetails?.plan_type) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Plan For:</th>
                  <td>{(planDetails?.subtitle) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Plan:</th>
                  <td>{(planDetails?.title) || 'NA'}</td>
                </tr>
              </tbody>
            </Table>
          </CardBody>

          <hr className='invoice-spacing' />

          {/* Payment Details */}
          <CardBody className='payment-details pb-4'>
            <h2 className='h2 mb-2'>Payment Details</h2>
            <Table responsive>
              <tbody>
                <tr>
                  <th>Date/Created AT:</th>
                  <td>{formatDate(paymentDetails.created_at) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Price:</th>
                  <td> {("₹" +paymentDetails.amount) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Payment Gateway ID:</th>
                  <td>{paymentDetails?.payment_gateway_transaction_id || 'NA'}</td>
                </tr>
                <tr>
                  <th>Amount Paid:</th>
                  <td>₹ {paymentDetails?.payment_response?.amount || 'NA'}</td>
                </tr>
                <tr>
                  <th>Merchant ID:</th>
                  <td>{paymentDetails?.payment_response?.merchantId || 'NA'}</td>
                </tr>
                <tr>
                  <th>Merchant Transaction ID:</th>
                  <td>{paymentDetails?.payment_response?.merchantTransactionId || 'NA'}</td>
                </tr>
                <tr>
                  <th>Bank ID:</th>
                  <td>{paymentDetails?.payment_response?.paymentInstrument?.bankId || 'NA'}</td>
                </tr>
                <tr>
                  <th>Bank Transaction ID:</th>
                  <td>{paymentDetails?.payment_response?.paymentInstrument?.bankTransactionId || 'NA'}</td>
                </tr>
                <tr>
                  <th>PG Service Transaction ID:</th>
                  <td>{paymentDetails?.payment_response?.paymentInstrument?.pgServiceTransactionId || 'NA'}</td>
                </tr>
                <tr>
                  <th>PG Transaction ID:</th>
                  <td>{paymentDetails?.payment_response?.paymentInstrument?.pgTransactionId || 'NA'}</td>
                </tr>
                <tr>
                  <th>Payment Mode:</th>
                  <td>{paymentDetails?.payment_response?.paymentInstrument?.type || 'NA'}</td>
                </tr>
                <tr>
                  <th>Response:</th>
                  <td>{paymentDetails.responseCode || 'NA'}</td>
                </tr>
                <tr>
                  <th>State:</th>
                  <td>{paymentDetails.state || 'NA'}</td>
                </tr>
                <tr>
                  <th>Transaction UUID:</th>
                  <td>{paymentDetails.transaction_uuid || 'NA'}</td>
                </tr>
                <tr>
                  <th>Transaction ID:</th>
                  <td>{paymentDetails.transaction_uuid || 'NA'}</td>
                </tr>
                
                <tr>
                  <th>Updated AT:</th>
                  <td>{formatDate(paymentDetails.updated_at) || 'NA'}</td>
                </tr>
                
              </tbody>
            </Table>
          </CardBody>

          <hr className='invoice-spacing' />

          {/* Order Details */}
          <CardBody className='payment-details pb-4'>
            <h2 className='h2 mb-2'>Order Details</h2>
            <Table responsive>
              <tbody>
                <tr>
                  <th>Amount:</th>
                  <td>{("₹" + orderDetails.amount) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Coupon:</th>
                  <td>{orderDetails.coupon || 'NA'}</td>
                </tr>
                <tr>
                  <th>Created AT:</th>
                  <td>{formatDate(orderDetails.created_at) || 'NA'}</td>
                </tr>
                <tr>
                  <th>Payment Gateway:</th>
                  <td>{orderDetails.payment_gateway || 'NA'}</td>
                </tr>
                <tr>
                  <th>Status:</th>
                  <td>{orderDetails.status || 'NA'}</td>
                </tr>
                
              </tbody>
            </Table>
          </CardBody>
        </Card>
        : ''}


      {/* <Modal isOpen={show} toggle={() => setShow(!show)} className='modal-dialog-centered modal-xl'>
        <ModalHeader className='bg-transparent' toggle={() => setShow(!show)}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <h1 className='text-center mb-1'>Subscription Plan</h1>
          <p className='text-center mb-3'>
            All plans include 40+ advanced tools and features to boost your product. Choose the best plan to fit your
            needs.
          </p>
          <div className='d-flex align-items-center justify-content-center mb-5 pb-50'>
            <h6 className='me-50 mb-0'>Monthly</h6>
            <div className='form-switch'>
              <Input id='plan-switch' type='switch' checked={duration === 'yearly'} onChange={onChange} />
            </div>
            <h6 className='ms-50 mb-0'>Annually</h6>
          </div>
          <PricingCard bordered data={data} duration={duration} />
          <div className='text-center'>
            <p>Still not convinced? Start with a 14-day FREE trial!</p>
            <Button color='primary' onClick={() => setShow(!show)}>
              Start your trial
            </Button>
          </div>
        </ModalBody>
      </Modal> */}
    </Fragment>
  )
}

export default BillingCurrentPlan
