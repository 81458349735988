import { useState } from "react";
import { X } from "react-feather";
const BlogLinkSection = ({setShowBlogLink}) => {
    const BlogLink = 'https://www.linkedin.com/pulse/boosting-business-efficiency-ai-financial-investigations-be1kc/?trackingId=STXur2wv7WCMXdZa1nWuoA%3D%3D';

    const handleClick =()=>{
        setShowBlogLink(false);
    }
    return (
        
        <div className="blogs-link-section">
            <p>💡 Did you checkout our latest blogs on 
            “<a href={BlogLink} target="_blank" rel="noopener noreferrer">Boosting Business Efficiency with AI in Financial Investigations</a>”
            </p>
            <button className="btn close-btn" onClick={handleClick}><X /></button>
        </div>
    )
}

export default BlogLinkSection;