// ** React Imports
import React, { useRef, useState } from "react";
import { Link } from 'react-router-dom'

import InsightAILogo from '@src/assets/images/logo/LogoDark@2x.png'
// ** Custom Components
import Wizard from '@components/wizard'

// ** Reactstrap Imports
import { Col, Row } from "reactstrap";
import { Globe, User } from "react-feather";
import AccountDetails from './steps/AccountDetails'
// ** Styles
import '@styles/react/pages/page-authentication.scss'
import CompanyProfile from "./steps/CompanyProfile";

const Register = () => {
  const [stepper, setStepper] = useState(null)

  // ** Ref
  const ref = useRef(null)

  const steps = [
    {
      id: 'account-details',
      title: 'Account',
      subtitle: 'Account Details',
      icon: <User size={18} />,
      content: <AccountDetails stepper={stepper} />
    },
    {
      id: 'company-profile',
      title: 'Company',
      subtitle: 'Company Profile',
      icon: <Globe size={18} />,
      content: <CompanyProfile stepper={stepper} />
    },
  ]

  const source = require('@src/assets/images/pages/create-account.png').default

  return (
    <div className='auth-wrapper auth-cover'>
      <Row className='auth-inner m-0'>
        <Link className="brand-logo" to="/">
          <img src={InsightAILogo} style={{ width: "140px", height: "30px" }} alt={"logo"} />
        </Link>
        <Col lg='3' className='d-none d-lg-flex align-items-center p-0'>
          <div className='w-100 d-lg-flex align-items-center justify-content-center'>
            <img className='img-fluid w-100' src={source} alt='' />
          </div>
        </Col>
        <Col lg='9' className='d-flex align-items-center auth-bg px-2 px-sm-3 px-lg-5 pt-3'>
          <div className='width-700 mx-auto'>
            <Wizard
              ref={ref}
              steps={steps}
              instance={el => setStepper(el)}
              headerClassName='px-0'
              contentWrapperClassName='px-0 mt-4'
              className='register-multi-steps-wizard shadow-none'
            />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default Register
