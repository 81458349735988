// ** React Imports
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// ** Custom Hooks
import { useSkin } from "@hooks/useSkin";

import {
  toastStyle,
  isValidEmail,
  showErrorToast, saveDataToLocalStorage
} from "../../utility/helper";
import { statusCode } from "../../utility/constants/utilObject";

//Social Logins
import {
  LoginSocialGoogle
} from "reactjs-social-login";

// ** Third Party Components
import toast from "react-hot-toast";
import InsightAILogo from "@src/assets/images/logo/LogoDark@2x.png";
import FrontPageBG from "@src/assets/images/backgrounds/frontpage_bg.svg";
import {
  LOGIN_TYPE,
  ONBOARDING_STATUS,
  _parseDataToPayload
} from "../../utility/constants/socialMediaPlatform";

// ** Actions
import {
  loginUserHandler,
  signupThirdPartyUserHandler,
  //linkedinAuthSetupHandler
} from "../../services/onboarding";

import GoogleSignInButton from "./socialLoginScreens/GoogleLoginBtn";

import InputPasswordToggle from "@components/input-password-toggle";
import "./index.scss";

// ** Reactstrap Imports
import {
  Form,
  Input,
  Label,
  Button,
  CardText,
  CardTitle,
  Spinner,
  Col,
  Row
} from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import { Linkedin, Mail } from "react-feather";

// preventWebLoading();

const REDIRECT_URI = `${window.location.origin}/login`;

const Login = () => {
  // ** Hooks
  const { skin } = useSkin();
  const navigate = useNavigate();
  // const source = require(`@src/assets/images/background/frontpage_bg.svg`);

  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({
    email: "",
    password: ""
  });

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  // Use the login api to set up the access
  const handleThirdPartyLoginSubmit = (data, loginType) => {
    const payload = _parseDataToPayload(data, loginType);
    console.log("Payload: ", payload);
    payload["origin"] = window.location.origin;
    setLoader(true);
    // signupThirdPartyUserHandler(payload)
    //   .then((res) => {
    //     setLoader(false);
    //     if (res.status && (res.status.toString() === statusCode.HTTP_201_CREATED) || (res.status.toString() === statusCode.HTTP_200_OK)) {
    //       const response = res.data.data;
    //       saveDataToLocalStorage(response);
    //       if (response.status === ONBOARDING_STATUS.ONBOARDING_IN_PROCESS) {
    //         localStorage.setItem("temp_refresh", response.refresh);
    //         localStorage.setItem("temp_token", response.access);
    //         toast.success("Successfully created the user account", { ...toastStyle.success });
    //         navigate("/register");
    //       } else {
    //         toast.success("Successfully created the user account", { ...toastStyle.success });
    //         navigate("/dashboard");
    //       }
    //     } else {
    //         toast.error(`${res.response && res.response.data && res.response.data.error ? res.response.data.error : "Something went wrong, Please try again"}`,
    //           { ...toastStyle.error });
    //     }
    //   })
    //   .catch((err) => {
    //     setLoader(false);
    //     showErrorToast(`${err.message || "Something went wrong, Please try again"}`);
    //   });
  };

  // Use the login api to set up the access
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!data.email || !data.password) {
      return toast.error("Please provide all details", { ...toastStyle.error });
    } else if (!isValidEmail(data.email)) {
      return toast.error("Invalid email address", { ...toastStyle.error });
    } else if (data.email && data.password) {
      setLoader(true);
      loginUserHandler({
        email: data.email,
        password: data.password
      })
        .then((res) => {
          setLoader(false);
          if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
            const response = res.data.data;
            saveDataToLocalStorage(response);
            localStorage.removeItem('shopify');
            localStorage.removeItem('bot_config');
            localStorage.removeItem('bot_id');
            localStorage.removeItem('dashboard');
            localStorage.removeItem('pricing');

            const isEmailVerified = response["is_email_verified"];
            if (!isEmailVerified) {
              window.location.replace("/verify-email")
              return;
            }
            toast.success("Welcome back " + response.first_name, { ...toastStyle.success });
            window.location.replace("/dashboard");
          } else {
            toast.error(`${res.response && res.response.data && res.response.data.error ? res.response.data.error : "Unable to login, Please check your credentials."}`, { ...toastStyle.error });
          }
        })
        .catch((err) => {
          setLoader(false);
          return toast.error(`${err.message || "Incorrect credentials entered"}`, { ...toastStyle.error });
        });
    }
  };

  // const linkedinSetupInit = (authCode) => {
  //   if (!authCode) {
  //     toast.error("Auth code not present", { ...toastStyle.error });
  //   } else {
  //     setLoader(true);
  //     linkedinAuthSetupHandler({
  //       auth_code: authCode,
  //       redirect_uri: `${window.location.origin}/auth/linkedin`
  //     })
  //       .then((res) => {
  //         if (res.status === statusCode.HTTP_201_CREATED) {
  //           localStorage.setItem("id", res.data.data._id);
  //           localStorage.setItem("first_name", res.data.data.first_name);
  //           localStorage.setItem("last_name", res.data.data.last_name);
  //           localStorage.setItem("email", res.data.data.email);
  //           localStorage.setItem("image_url", res.data.data.image_url);
  //           localStorage.setItem("refresh", res.data.data.refresh);
  //           localStorage.setItem("token", res.data.data.access);
  //           toast.success("Welcome " + res.data.data.first_name, { ...toastStyle.success });
  //           setLoader(false);
  //           window.location.replace("/dashboard");
  //         } else {
  //           setLoader(false);
  //           toast.error("Could not setup Linkedin. Please try again.", { ...toastStyle.error });
  //         }
  //       }).catch((error) => {
  //       console.log("Error: ", error);
  //       setLoader(false);
  //       toast.error("Could not setup Linkedin. Please try again.", { ...toastStyle.error });
  //     });
  //   }
  // };

  return (
    <div className="auth-wrapper auth-cover">
      <Row className="auth-inner m-0">
        <Link className="brand-logo" to="/" onClick={e => e.preventDefault()}>
          <img src={InsightAILogo} style={{ width: "140px", height: "30px" }} alt={"logo"} />
        </Link>
        <Col className="d-none d-lg-flex align-items-center p-5" lg="8" sm="12">
          <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
            <img style={{maxHeight:'75vh'}} className="img-fluid" src={FrontPageBG} alt="Login Cover" />
          </div>
        </Col>
        <Col className="d-flex align-items-center auth-bg px-2 p-lg-5" lg="4" sm="12">
          <Col style={{
              boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px', 
              paddingTop: '50px',
              borderRadius: '5px',
              paddingBottom: '50px',
            }} className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
            <CardTitle style={{textAlign:'center'}} tag="h2" className="fw-bold mb-1">
              Welcome to Insight AI! 👋
            </CardTitle>
            <CardText style={{textAlign:'center'}} className="mb-2">Please sign-in to your account and start the adventure</CardText>
            {/* Third Party Sign In */}
          
            {/* <div className="auth-footer-btn d-flex justify-content-center">
              <LoginSocialGoogle
                className={loader ? "disabled-link" : ""}
                client_id={process.env.REACT_APP_GG_APP_ID}
                redirect_uri={REDIRECT_URI}
                scope="openid profile email"
                discoveryDocs="claims_supported"
                access_type="offline"
                onReject={(err) => setLoader(false)}
                onResolve={({ provider, data }) => {
                  handleThirdPartyLoginSubmit(data, LOGIN_TYPE.GOOGLE);
                }}
              >
                <GoogleSignInButton />
              </LoginSocialGoogle>
            </div>
            <div className="divider my-2">
              <div className="divider-text">or</div>
            </div> */}

            {/* Email Sign in */}

            <Form style={{width:'100%'}} className="auth-login-form mt-2" onSubmit={handleSubmit}>
              <div className="mb-1">
                <Label className="form-label" for="login-email">
                  Email
                </Label>
                <Input
                  type="email"
                  disabled={loader}
                  id="login-email"
                  placeholder="john@example.com"
                  autoFocus name="email" value={data.email}
                  onChange={handleChange}
                />
              </div>
              <div className="mb-1">
                <div className="d-flex justify-content-between">
                  <Label className="form-label" for="login-password">
                    Password
                  </Label>
                  <Link className={loader ? "disabled-link" : ""} to="/forgot-password">
                    <small>Forgot Password?</small>
                  </Link>
                </div>
                <InputPasswordToggle
                  disabled={loader}
                  className="input-group-merge"
                  id="login-password"
                  placeholder="Enter your password"
                  name="password"
                  value={data.password}
                  onChange={handleChange}
                />
              </div>
              <div className="form-check mb-1">
                <Input disabled={loader} type="checkbox" id="remember-me" />
                <Label className="form-check-label" for="remember-me">
                  Remember Me
                </Label>
              </div>
              {loader ?
                <div className="sipnner-container">
                  <Spinner color="primary" />
                </div> :
                <Button color="primary" block disabled={loader}>
                  Sign in
                </Button>
              }
            </Form>
            <p style={{fontSize:'16px'}} className="text-center mt-2">
              <span className="me-25">New on our platform?</span>
              <Link className={loader ? "disabled-link" : ""} to="/register">
                <span>Create an account</span>
              </Link>
            </p>
          </Col>
        </Col>
      </Row>
    </div>);
};

export default Login;
