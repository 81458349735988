// ** React Imports
import { lazy } from 'react';
import Billing from '../../views/pages/Billing';
import Dashboard from '../../views/Dashboard';
import ContactUs from "../../views/pages/ContactUs";
import BankTemplateSelection from '../../views/BankStatementParser/BankingAPISelection';
import Banks from '../../views/BankStatementParser/Banks';
import AccountSettings from '../../views/Profile/account-settings';
// import Pricing from '../../views/pages/pricing';
import DocumentIntelligence from '../../views/GenerativeAISolution/ChatBot';
import PreviousChatSessions from '../../views/GenerativeAISolution/PreviousChatSessions';
import ZohoAccess from '../../views/ThridPartyIntegrations/ZohoAccess';
// const ApiDashboard = lazy(() =>import('../../views/ApiDashboard'))
import ApiDashboard from '../../views/ApiDashboard';
import BankParsing from '../../views/BankStatementParser/Banks';
import UploadStatement from '../../views/BankStatementParser/Banks/UploadSatement';
import CompanyValuation from '../../views/pages/CompanyValuation';
import AnalysisTable from '../../views/BankStatementParser/Banks/AnalysisTable';
import KnowledgeCenter from '../../views/KnowledgeCenter';

import FinancialStatamentAnalysis from '../../views/Ledgers/FinancialStatementAnalytics/Analytics';
import FinancialStatementHistory from '../../views/Ledgers/FinancialStatementAnalytics/History';
// Trial balance
import TrialBalanceAnalysis from '../../views/Ledgers/TrialBalance/Analytics';
import TrialBalanceHistory from '../../views/Ledgers/TrialBalance/History';
// Scanned PDF
import ScannedPDFHistory from '../../views/Ledgers/ScannedPDF/History';
import ScannedPDFAnalysis from '../../views/Ledgers/ScannedPDF/Analytics';
// Chatbot
import ChatBot from '../../views/ChatBot';
import ChatbotProfile from '../../views/ChatBot/ChatbotProfile';
import Ledgers from '../../views/Ledgers';


const AppRoutes = [
  // Dashboard api section
  {
    element: <Dashboard/>,
    path: '/dashboard',
    meta: { publicRoute: false }
  },

  // Analysis section
  {
    path: '/analysis',
    element: <BankTemplateSelection />,
    meta: { publicRoute: false }
  },
  {
    path: '/financial-statement-analyser',
    element: <Banks />,
    meta: { publicRoute: true }
  },
  {
    path: '/document-intelligence/:session',
    element: <DocumentIntelligence />,
    meta: { publicRoute: false }
  },
  {
    path: '/document-intelligence-history',
    element: <PreviousChatSessions />,
    meta: { publicRoute: false }
  },

  // profile
  // {
  //   path: '/pricing',
  //   element: <Pricing />,
  //   meta: { publicRoute: false }
  // },
  {
    path: '/pages/account-settings',
    element: <AccountSettings />
  },
  {
    element: <Billing />,
    path: '/billing',
    meta: { publicRoute: false }
  },
  {
    element: <ContactUs />,
    path: '/contact',
    meta: { publicRoute: false }
  },

  // Ledger and Books
  {
    element: <Ledgers />,
    path: '/ledger-and-books',
    meta: { publicRoute: false }
  },

  // Financial Statement Analytics
  {
    element: <FinancialStatamentAnalysis />,
    path: '/financial-statement-analytics/:document',
    meta: { publicRoute: false }
  },

  {
    element: <FinancialStatementHistory />,
    path: '/financial-statements',
    meta: { publicRoute: false }
  },

  // Scanned PDF
  {
    element: <ScannedPDFAnalysis />,
    path: '/scanned-pdf-analytics/:document',
    meta: { publicRoute: false }
  },

  {
    element: <ScannedPDFHistory />,
    path: '/scanned-pdfs',
    meta: { publicRoute: false }
  },

  // Trial Balance

  {
    element: <TrialBalanceAnalysis />,
    path: '/trial-balance-analytics/:document',
    meta: { publicRoute: false }
  },

  {
    element: <TrialBalanceHistory />,
    path: '/trial-balances',
    meta: { publicRoute: false }
  },

  // Third Party Apps
  {
    element: <ZohoAccess />,
    path: '/access',
    meta: { publicRoute: false }
  },
  {
    path: '/pages/account-settings',
    element: <AccountSettings />
  },
  {
    path: '/api-dashboard',
    element: <ApiDashboard />,
    meta: { publicRoute: true }
  },
  {
    path: '/financial-statement-analyser',
    element: <BankParsing />,
    meta: { publicRoute: true }
  },
  {
    path: '/financial-statement-analyser/upload-statement',
    element: <UploadStatement />,
    meta: { publicRoute: true }
  },
  {
    path: '/company-valuation-report',
    element: <CompanyValuation />,
    meta: { publicRoute: true }
  },
  {
    path: '/upload-history',
    element: <AnalysisTable />,
    meta: { publicRoute: true }
  },
  {
    path: '/upload-history/:statement_id',
    element: <AnalysisTable />,
    meta: { publicRoute: true }
  },
  {
    path: '/knowledge-center',
    element: <KnowledgeCenter />,
    meta: { publicRoute: true }
  },
  {
    path: '/chatbot-dashboard',
    element: <ChatBot />,
    meta: { publicRoute: true }
  },
  {
    path: '/chatbot-dashboard/:bot_id',
    element: <ChatbotProfile />,
    meta: { publicRoute: true }
  },
  {
    path: '/chatbot-dashboard/:bot_id/:bot_tab',
    element: <ChatbotProfile />,
    meta: { publicRoute: true }
  },
]

export default AppRoutes;
