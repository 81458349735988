// ** Icons Import
import { Home, Database, User, PieChart, HardDrive, MessageSquare, Calendar } from 'react-feather'
import { useNavigate } from "react-router-dom";
import { connect } from 'react-redux';
import "./index.scss"

const Footer = (props) => {
  const navigate = useNavigate();
  {/* The footer is used as a bottom navigation here */}
  const { navbarPayload } = props;
  let pageName = "CenterStage";
  if(navbarPayload && navbarPayload.pageName){
    pageName = navbarPayload.pageName
  }

  return (
    <div className='bottomNav clearfix mb-0' style={{height:'50px', width:'100%'}}>
        <div 
          onClick={()=>navigate('/trending')} 
          className={`bottomNavItem ${pageName==='Trending'?'selectedBottomNavItem':''}`}
        >
          <Home size={'17px'} style={{textAlign:'center'}}/>
          <div>Home</div>
        </div>
        <div 
          onClick={()=>navigate('/your-posts')} 
          className={`bottomNavItem ${pageName==='Your Posts'?'selectedBottomNavItem':''}`}
        >
          <HardDrive size={'17px'} style={{textAlign:'center'}}/>
          <div>Draft</div>
        </div>
        <div 
          onClick={()=>navigate('/analytics')} 
          className={`bottomNavItem ${pageName==='Analytics'?'selectedBottomNavItem':''}`}
        >
          <PieChart size={'17px'} style={{textAlign:'center'}}/>
          <div>Analytics</div>
        </div>
        <div 
          onClick={()=>navigate('/notes')} 
          className={`bottomNavItem ${pageName==='Notes'?'selectedBottomNavItem':''}`}
        >
          <MessageSquare size={'17px'} style={{textAlign:'center'}}/>
          <div>Notes</div>
        </div>
    </div>
  )
}

const mapDispatchToProps = {

};

const mapStateToProps = ({ navbarContent }) => {
  return {
    navbarPayload: navbarContent.payload,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
