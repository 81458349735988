import { useState, useEffect } from "react";

//progressbar
import { Card, CardBody, Progress, Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { FaCheckCircle } from "react-icons/fa";

import "./style.scss";

//AnalysisReport

import AnalysisReport from "./AnalysisReport";

//Copy
import copy from "copy-to-clipboard";
import axios from "axios";

const StatementProcess = (props) => {
  const totalTime = 10;
  const [timeLeft, setTimeLeft] = useState(totalTime);
  const [progress, setProgress] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [documentName, setDocumentName] = useState("");

  const { selectedItem, files } = props;


  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(prevTimeLeft => {
        if (prevTimeLeft > 0) {
          const newTimeLeft = prevTimeLeft - 1;

          const newProgress = 100 - (newTimeLeft / totalTime) * 100;
          setProgress(newProgress);
          return newTimeLeft;
        } else {
          clearInterval(interval);
          setCompleted(true);
          return 0;
        }
      });
    }, 1000);

    return () => clearInterval(interval);
  }, [totalTime]);

  //files
  useEffect(() => {
    if (files) {
      files.map((item, i) => {
        return (
          setDocumentName(item.name)
        );
      });
    }

  }, [files]);


  const xml = `{
    "account_details": {
        "account_number": "50200067364722",
        "customer_address": "C/O PINNACLE TECHNOLOGIES AND M S P B1/H3 MOHAN CO-OPERATIVE INDUSTRIAL AREA MATHURA ROAD BLOCK B NEW DELHI 110044",
        "customer_name": "M/S. PINNACLE TECHNOLOGIES AND M S PVT LTD",
        "ifsc_code": "HDFC0002034",
        "statement_period": {
            "from_date": "2023-07-01",
            "to_date": "2023-07-31"
        }
    },
    "analyzed_details": {
        "CASH DEPOSITS": [],
        "CASH WITHDRAWALS": [],
        "CHEQUE BOUNCES": [],
        "CHEQUE DEPOSITS": [],
        "CHEQUE WITHDRAWALS": [],
        "ECS BOUNCES": [],
        "EMI TRXN": null,
        "EOD BALANCE": {
            "daywise_eod_balance": [
                {
                    "2023-07-03": 115870.91
                },
                {
                    "2023-07-04": 2870.91
                }
            ],
            "months": [
                "2023-07"
            ],
            "monthwise_eod_balance": {
                "2023-07": {
                    "25th percentile eod balance": 31120.91,
                    "50th percentile eod balance": 59370.91,
                    "75th percentile eod balance": 87620.91,
                    "avg eod balance": 59370.91,
                    "max eod balance": 115870.91,
                    "min eod balance": 2870.91
                }
            }
        },
        "LOAN TRXN": null,
        "PAYMENT BOUNCES": [],
        "SALARY TRXN": []
    },
    "fraud_details": {
        "fraud_flag": false,
        "fraud_markers": []
    },
    "parsing_status": true,
    "trxn_details": [
        {
            "amount": 50000.0,
            "balance": 52870.91,
            "date": "2023-07-03",
            "narration": "50100134650536-TPT-DIRECTOR LOAN 1-AKASH CHANDRA",
            "trxn_type": "Other"
        },
        {
            "amount": 50000.0,
            "balance": 102870.91,
            "date": "2023-07-03",
            "narration": "50100134650536-TPT-DIRECTOR LOAN 2-AKASH CHANDRA",
            "trxn_type": "Other"
        },
        {
            "amount": 50000.0,
            "balance": 152870.91,
            "date": "2023-07-03",
            "narration": "50100134650536-TPT-DIRECTOR LOAN 3-AKASH CHANDRA",
            "trxn_type": "Other"
        },
        {
            "amount": 50000.0,
            "balance": 202870.91,
            "date": "2023-07-03",
            "narration": "50100134650536-TPT-DIRECTOR LOAN 4-AKASH CHANDRA",
            "trxn_type": "Other"
        },
        {
            "amount": -87000.0,
            "balance": 115870.91,
            "date": "2023-07-03",
            "narration": "IMPS-318423102523-ABHAY RAJKISHOR SHARMA -ICIC-XXXXXXXX1159-FRONTEND PAYMENT FOR 1 MONTH 22 DAYS",
            "trxn_type": "IMPS Transfer"
        },
        {
            "amount": -70000.0,
            "balance": 45870.91,
            "date": "2023-07-04",
            "narration": "IMPS-318500104943-GAURAV GUPTA-FDRL-XXXX XXXXXX0272-DESIGN PAYMENT FOR MAY'23 AND JUNE'23",
            "trxn_type": "IMPS Transfer"
        },
        {
            "amount": -30000.0,
            "balance": 15870.91,
            "date": "2023-07-04",
            "narration": "IMPS-318500107376-SAKSHI MAKKAR HAVSTRAT EGY-HDFC-XXXXXXXXXX0936-DIGITAL MARKETIN G PAYMENT",
            "trxn_type": "IMPS Transfer"
        },
        {
            "amount": -10000.0,
            "balance": 5870.91,
            "date": "2023-07-04",
            "narration": "IMPS-318500108816-SRISHTI NARASIMHA KAMA TH-ICIC-XXXXXXXX6255-INTERNSHIP STIPEND",
            "trxn_type": "IMPS Transfer"
        },
        {
            "amount": 22000.0,
            "balance": 27870.91,
            "date": "2023-07-04",
            "narration": "50100134650536-TPT-DIRECTOR LOAN 5-AKASH CHANDRA",
            "trxn_type": "Other"
        },
        {
            "amount": -25000.0,
            "balance": 2870.91,
            "date": "2023-07-04",
            "narration": "IMPS-318517123765-ABU KASHAN-CNRB-XXXXXX XXX3253-DATA SCIENCE INTERNSHIP STIPEND",
            "trxn_type": "IMPS Transfer"
        }
    ]
}`;

  return (
    <>

      {
        selectedItem ?
          (<div className="document-process">
            {/* <div className="bank-details d-flex justify-content-center align-items-center mb-2">
              <img src={selectedItem?.image_url || ""} className="w-5" />
              <h2 className="">{selectedItem?.name || ""}</h2>
            </div> */}


            <div className="document-process__notification">
              {
                completed ? (
                    // <h2 className="document-process__title success">Statement Processed Successfully</h2>
                    ''
                  ) :
                  <>
                    <h2 className="document-process__title">Processing Your Statement...</h2>
                    <p>We will let you know, when we are done</p>
                  </>
              }
            </div>


            <div className="document-name-nd-loader">
              {
                !completed ? 
                <div className="my-3  process-indicator">
                <div className="progress-container">
                  <Progress
                    className="mb-0 document-progressbar"
                    color={completed ? "success" : "primary"}
                    value={progress}
                  />
                </div>
                <div className="process-info">
                  <h5>{documentName || ""}</h5>

                  {completed ? (
                    <span className="completed-text "><FaCheckCircle /></span>
                  ) : (
                    <span className="time-left">{timeLeft}s</span>
                  )}
                </div>

              </div>
              : ''
              }
              
              {completed ? (
                <div>
                  <AnalysisReport />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>)
          :
          <Card>
            <CardBody>
              <h2 className="text-center">No Documents Uploaded</h2>
            </CardBody>
          </Card>
      }
    </>
  );
};

export default StatementProcess;