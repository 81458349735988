/* eslint-disable */
import axios from 'axios';
import qs from 'qs';

const envType = {
  DEV: "development",
  STAGING: "staging",
  PROD: "production",
  US_PROD: "us_production",
}

const develop = envType.US_PROD;

const cred = {
    url: {
      development: "http://localhost:8080/",
      staging: "https://dev-app.insightai.in:8080/",
      production: "https://api-server.insightai.in:8080/",
      us_production: "https://fin-app.insightai.in:8080/",
    },
    client_secret: {
      development: "2d1376db3fa7e6d87e1c0e119da0d4c0d882704a94131196dd1ee550a192d4ac",
      staging: "2d1376db3fa7e6d87e1c0e119da0d4c0d882704a94131196dd1ee550a192d4ac",
      production: "ced1801c5d9387970ed36b04911971ecd6719502ea51ffdc33fa5237bd463a16",
    },
    secret_token: {
      development: "C5w6LFkJqedI7pBY9_WNiDAM9H5OkGa4oiGBlsaeVzs",
      staging: "C5w6LFkJqedI7pBY9_WNiDAM9H5OkGa4oiGBlsaeVzs",
      production: "04AppdespbfE_mXkru8qfxeXyTtBSMV83IUMwR2fjCM",
    },
    client_id: {
      development: "centerstage_dev",
      staging: "centerstage_stage",
      production: "centerstage_prod",
    },
};

const fetchUrlPrefix = () => {
  switch (develop) {
    case envType.DEV:
      return cred.url.development;
    case envType.STAGING:
      return cred.url.staging;
    case envType.PROD:
      return cred.url.production;
    case envType.US_PROD:
      return cred.url.us_production;
    default:
      return cred.url.development;
  }
}


export const WEB_ROUTE = {
    urlPrefix: fetchUrlPrefix(),
    clientSecret: develop == envType.DEV ? cred.client_secret.development : develop == envType.STAGING ? cred.client_secret.staging : cred.client_secret.production,
}


export const REQUESTS = {
	get : async (url, token) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        axios.defaults.headers.common["ngrok-skip-browser-warning"]= "skip-browser-warning"
		    return axios.get(`${WEB_ROUTE.urlPrefix}${url}`)
					.then(res => { return res })
					.catch((error)=> { return error })
	},
  post_without_token : async (url ,body) => {
    return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({...body}))
      .then(res => { return res })
      .catch((error)=> { return error })
  },
	post : async (url, token ,body) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({...body}))
					.then(res => { return res })
					.catch((error)=> { return error })
	},

  postFile : async (url, token ,body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    axios.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    return axios.post(`${WEB_ROUTE.urlPrefix}${url}`, body)
      .then(res => { return res })
      .catch((error)=> { return error })
  },

  put : async (url, token ,body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.put(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({...body}))
      .then(res => { return res })
      .catch((error)=> { return error })
  },
  patch : async (url, token ,body) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
    return axios.patch(`${WEB_ROUTE.urlPrefix}${url}`, qs.stringify({...body}))
      .then(res => { return res })
      .catch((error)=> { return error })
  },
	delete : async (url, token) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
        return axios.delete(`${WEB_ROUTE.urlPrefix}${url}`)
					.then(res => { return res })
					.catch((error)=> { return error })
	},
  uploadFile : async  (url, token ,formData) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
		    return  axios.post(`${WEB_ROUTE.urlPrefix}${url}`, formData, { headers: { 'Content-Type': 'multipart/form-data'}})
          .then(res => { return res; })
          .catch((error)=> { return error;})
	},
}
