import { REQUESTS } from "./apiConfig";

const API_ROUTES = {
  uploadeStatement: "analytics/ledger/process_financial_statement/?ref={0}",
  fetchSingleStatementAnalysis: "analytics/ledger_data/{0}/",
  fetchSingleTrialBalanceAnalysis: "analytics/ledger_trail/{0}/",
  fetchAllFinancialStatements: "analytics/ledger/fetch_all_statement/?type={0}&document_type={1}",
  fetchStatementAnalysis: "analytics/ledger/fetch_statement_analysis/?id={0}"
};

export const uploadStatementHandler = (payload, socketId) => {
  return REQUESTS.postFile(API_ROUTES.uploadeStatement.format(socketId), localStorage.getItem("token"), payload);
}

export const fetchSingleStatementAnalysisHandler = (statementId) => {
  return REQUESTS.get(API_ROUTES.fetchSingleStatementAnalysis.format(statementId), localStorage.getItem("token"));
}

export const fetchAllFinancialStatementsHandler = (type, format) => {
  return REQUESTS.get(API_ROUTES.fetchAllFinancialStatements.format(type, format), localStorage.getItem("token"));
}

export const fetchStatementAnalysisHandler = (id) => {
  return REQUESTS.get(API_ROUTES.fetchStatementAnalysis.format(id), localStorage.getItem("token"));
}

export const fetchSingleTrialBalanceAnalysisHandler = (statementId) => {
  return REQUESTS.get(API_ROUTES.fetchSingleTrialBalanceAnalysis.format(statementId), localStorage.getItem("token"));
}