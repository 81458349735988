// ** React Imports
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

// ** Custom Hooks
import { useSkin } from "@hooks/useSkin";
import {
  toastStyle,
  isValidEmail,
  isValidPassword,
  getUserNameFromEmail,
  saveDataToLocalStorage
} from "../../../utility/helper";
import { statusCode } from "../../../utility/constants/utilObject";
import { LOGIN_TYPE, _parseDataToPayload } from "../../../utility/constants/socialMediaPlatform";

// ** Third Party Components
import toast from "react-hot-toast";
import { verifyEmailHandler } from "../../../services/user";
import { showErrorToast, showSuccessToast } from "../../../utility/helper";

// ** Reactstrap Imports
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import { colors } from "../../../utility/constants/colors";

const VerifyEmail = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [emailVerified, setEmailVerified] = useState(true);
  const token = searchParams.get("token");


  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      verifyEmailHandler(token)
        .then((res) => {
          if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
            saveDataToLocalStorage(res.data.data);
            setEmailVerified(true);
            showSuccessToast("Email verified");
            setTimeout(() => navigate('/onboarding'), 2500);
          } else if (res.request && res.request.status && res.request.status.toString() === statusCode.HTTP_400_BAD_REQUEST) {
            setEmailVerified(false);
            showErrorToast("Email could not be verified");
          }
        })
        .catch((err) => {
          setEmailVerified(false);
          showErrorToast("Email could not be verified");
        });
    }
  }, []);

  const renderStatus = () => {
    if (emailVerified === null) {
      return (
        <CardTitle style={{ textAlign: "center" }} tag="h4" className="mb-1">
          <Spinner color="primary" />
          <div style={{ color: colors.primaryColor }}>Please wait while we verify your email...</div>
        </CardTitle>
      );
    } else if (emailVerified == false) {
      return (
        <CardTitle style={{ textAlign: "center" }} tag="h4" className="mb-1">
          <div style={{ color: colors.errorRed }}>Email could not be verified</div>
        </CardTitle>
      );
    } else if (emailVerified == true) {
      return (
        <CardTitle style={{ textAlign: "center" }} tag="h4" className="mb-1">
          <div style={{ color: colors.editorGreen }}>Email verified</div>
        </CardTitle>
      );
    }
  };

  return (
    <div className="auth-wrapper auth-basic px-2">
      <div className="auth-inner my-2">
        <Card className="mb-0">
          <CardBody>
            <h2 style={{ textAlign: "center", marginBottom: "40px" }} className="brand-text text-primary ms-1">
              InsightAI
            </h2>
            {renderStatus()}
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default VerifyEmail;
