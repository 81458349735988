// ** React Imports
import { lazy } from 'react'
import { LinkedInCallback } from "react-linkedin-login-oauth2";

import Register from '../../views/Authentication/Register';
import Onboarding from '../../views/Authentication/Register/steps/CompanyProfile';
import Login from '../../views/Authentication/Login';
import VerifyEmail from '../../views/Authentication/VerifyEmailSuccess';
import EmailVerify from '../../views/Authentication/EmailVerify';
import ForgotPassword from "../../views/Authentication/ForgetPassword";
import ResetPassword from "../../views/Authentication/ResetPassword";

const LinkedInAuthSetup = lazy(()=> import('../../views/Authentication/socialLoginScreens/LinkedInAuthSetup') )

const Authentication = [
  {
    path: '/login',
    element: <Login/>,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/verify-email',
    element: <EmailVerify />,
    meta: {
      layout: 'blank',
      publicRoute: true,
    }
  },
  {
    path: '/email-verify',
    element: <VerifyEmail/>,
    meta: {
      layout: 'blank',
      publicRoute: true,
    }
  },
  {
    element: <Onboarding/>,
    path: '/onboarding',
    meta: {
        layout: 'blank',
        publicRoute: true,
        restricted: true,
    }
  },
  {
    element: <LinkedInCallback/>,
    path: '/auth/linkedin',
    meta: {
        layout: 'blank',
        publicRoute: true,
    }
  },
  // {
  //   path: '/register',
  //   element: <Register/>,
  //   meta: {
  //     layout: 'blank',
  //     publicRoute: true,
  //   }
  // },
  {
    path: '/account-setup/linkedin',
    element: <LinkedInAuthSetup/>,
    meta: {
      layout: 'blank',
      publicRoute: true,
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true,
    }
  },
]

export default Authentication;
