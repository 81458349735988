// ** Icons Import
import Dashboard from "../../assets/logo/fintech-dashboard.png";
import Billing from "../../assets/logo/billing.png";
import BSPIcon from "../../assets/logo/banking.png";
import Document from "../../assets/icons/google-docs.png";
import Knowledge from "../../assets/logo/Knowledge.png";
import Ledger from "../../assets/icons/ledger.png";
import Bot from "../../assets/images/bot/robot.png";
import FinanceDoc from "../../assets/icons/finance_doc.png";



const navIcon = (source) => (
  <img src={source} style={{ width: "20px", height: "20px" }} alt={''}/>
)

const navIconGroup = (source) => (
  <img src={source} style={{ width: "20px", height: "20px", marginLeft: '-5px', marginRight: '5px' }} alt={''}/>
)

let dashboard = [
  {
    id: 'cs-dashbaord',
    title: 'Dashboard',
    icon: navIcon(Dashboard),
    navLink: '/dashboard',
  },
  // {
  //   id: 'ledger-and-books',
  //   title: 'Ledger and Books',
  //   icon: navIcon(Ledger),
  //   navLink: '/ledger-and-books'
  // },
  {
    id: 'search-query',
    title: 'Doc Intelligence',
    icon: navIcon(Document),
    navLink: '/document-intelligence/new-session'
  },
  {
    id: 'search-query',
    title: 'ChatBot',
    icon: navIcon(Bot),
    navLink: '/chatbot-dashboard'
  },
  {
    id: 'knowledge-center',
    title: 'Knowledge Center',
    icon: navIcon(Knowledge),
    navLink: '/knowledge-center',
  },
  // {
  //   id: 'chat-bot-list',
  //   title: 'Document QnA',
  //   icon: navIconGroup(DocumentIntelligence),
  //   children: [
  //     {
  //       id: 'documents',
  //       title: 'Documents',
  //       icon: navIcon(Document),
  //       navLink: '/document/list'
  //     },
  //     {
  //       id: 'search-query',
  //       title: 'Query Search',
  //       icon: navIcon(Document),
  //       navLink: '/chat-bot'
  //     },
  //   ]
  // },
  // {
]

export default dashboard;
