//react
import { useState } from 'react';

// import BankSidebar from './Sidebar';

import { Button, Card, Input, InputGroup } from 'reactstrap'
import { Edit2 } from 'react-feather';


//Banks
import Banks from './Bank';

import PageHeader from "../../../@core/components/PageHeader";
//style
import './style.scss';

const CompanyValuation = () => {
    const [showInput, setShowInput] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [documentName, setDocumentName] = useState('New Document');
    const [bankName, setBankName] = useState('')

    const handleEdit = () => {
        setShowInput(true);
    }
    const handleInput = ()=>{
        if(inputValue){
            setDocumentName(inputValue);
            setShowInput(false);
        }
    }
    const handleBankName = (bank) =>{
        setBankName(bank)
    }
    
    return (
        <>
            <PageHeader
                pageName={"Company Valuation Report"}
                backButtonEnabled={false}
            />
            <Card>
                <div className='container-fluid vh-85'>
                    <div className='page-header'>
                        <div className='tab-container'>

                            {
                                showInput ?
                                    <div className='input-wrapper'>
                                        <InputGroup >
                                            <Input placeholder='Enter name' value={inputValue} onChange={(e)=>{setInputValue(e.target.value)}}/>
                                            <Button color='primary' onClick={handleInput}>
                                                Save
                                            </Button>
                                        </InputGroup>
                                    </div>
                                    :
                                    <div className='section-head'>
                                        <span>{documentName} {bankName?.name  && ' | ' + bankName?.name || ''}</span>
                                        <button onClick={handleEdit} className='edit-btn'><Edit2 /></button>
                                    </div>
                            }
                        </div>
                        <div className='btn-wrapper'>
                            <Button color='primary'>View History</Button>
                        </div>
                    </div>
                    <div className='page-container no-scroll-bar main-bsp'>
                        <div className='bsp-body'>
                            <Banks handleBank={handleBankName} />
                        </div>
                    </div>
                </div>
            </Card>
        </>
    )
}
export default CompanyValuation;