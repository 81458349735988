// ** React Imports
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

// ** Custom Hooks
import { useSkin } from "@hooks/useSkin";

// ** Icons Imports
import { ChevronLeft } from "react-feather";

// ** Custom Components
import InputPassword from "@components/input-password-toggle";

// ** Reactstrap Imports
import { Row, Col, CardTitle, CardText, Form, Label, Button } from "reactstrap";

// ** Styles
import "@styles/react/pages/page-authentication.scss";
import InsightAILogo from "@src/assets/images/logo/LogoDark@2x.png";
import { resetPassword } from "../../../services/user";
import { isValidPassword, toastStyle } from "../../../utility/helper";
import toast from "react-hot-toast";
import { statusCode } from "../../../utility/constants/utilObject";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get("reset-token");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // ** Hooks
  const { skin } = useSkin();
  const illustration = skin === "dark" ? "reset-password-v2-dark.svg" : "reset-password-v2.svg",
    source = require(`@src/assets/images/pages/${illustration}`).default;

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!password) {
      return toast.error("Password not entered", { ...toastStyle.error });
    }

    if (!confirmPassword) {
      return toast.error("Confirm password field cannot be empty", { ...toastStyle.error });
    }

    if (password.toLowerCase().toString() === confirmPassword.toLowerCase().toString()) {
      if (isValidPassword(password)) {
        resetPassword({ token: token, password: password })
          .then((res) => {
            if (res.status && res.status.toString() === statusCode.HTTP_200_OK) {
              setTimeout(() => {
                navigate("/login");
              }, 1500);
              return toast.success("Password successfully changed", { ...toastStyle.success });
            } else if (res.response && res.response.status.toString() === statusCode.HTTP_400_BAD_REQUEST) {
              toast.error(`${res.response.data && res.response.data.error ? res.response.data.error : res.response.data && Object.keys(res.response.data)[0] && `${Object.keys(res.response.data)[0].toUpperCase()} : ${res.response.data[Object.keys(res.response.data)[0]]}` || "Something went wrong"}`, { ...toastStyle.error });
            }
          }).catch(err => {
          console.log("err", err);
        });
      } else {
        return toast.error("Password validation failed", { ...toastStyle.error });
      }
    } else {
      return toast.error("Password did not match", { ...toastStyle.error });
    }
  };

  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    if (name === "password") {
      setPassword(value);
    }

    if (name === "confirmPassword") {
      setConfirmPassword(value);
    }
  };

  return (<div className="auth-wrapper auth-cover">
    <Row className="auth-inner m-0">
      <Link className="brand-logo" to="/" onClick={e => e.preventDefault()}>
        <img src={InsightAILogo} style={{ width: "140px", height: "30px" }} alt={"logo"} />
      </Link>
      <Col className="d-none d-lg-flex align-items-center p-5" lg="8" sm="12">
        <div className="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <img className="img-fluid" src={source} alt="Login Cover" />
        </div>
      </Col>
      <Col className="d-flex align-items-center auth-bg px-2 p-lg-5" lg="4" sm="12">
        <Col className="px-xl-2 mx-auto" sm="8" md="6" lg="12">
          <CardTitle tag="h2" className="fw-bold mb-1">
            Reset Password 🔒
          </CardTitle>
          <CardText className="mb-2">Your new password must be different from previously used passwords</CardText>
          <Form className="auth-reset-password-form mt-2" onSubmit={handleSubmit}>
            <div className="mb-1">
              <Label className="form-label" for="new-password">
                New Password
              </Label>
              <InputPassword className="input-group-merge" id="new-password" autoFocus name={"password"}
                             onChange={handlePasswordChange} />
            </div>
            <div className="mb-1">
              <Label className="form-label" for="confirm-password">
                Confirm Password
              </Label>
              <InputPassword onChange={handlePasswordChange} className="input-group-merge" id="confirm-password"
                             name={"confirmPassword"} />
            </div>
            <div style={{ opacity: 0.6 }}>
              <p>
                <small>Password must have 7+ characters, including at least 1 letter, 1 digit, and 1 special character
                  (e.g., @$!%*?&)
                </small>
              </p>
            </div>
            <Button color="primary" block>
              Set New Password
            </Button>
          </Form>
          <p className="text-center mt-2">
            <Link to="/login">
              <ChevronLeft className="rotate-rtl me-25" size={14} />
              <span className="align-middle">Back to login</span>
            </Link>
          </p>
        </Col>
      </Col>
    </Row>
  </div>);
};

export default ResetPassword;
