import React, { useState, useRef, useEffect } from 'react';

const CustomTabs = ({ tabs, 
    onTabSelect, 
    verticalJustify, 
    customStyle={}, 
    customTabStyle={}, 
    customSelectedTabStyle={} 
}) => {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
        if (onTabSelect) {
            onTabSelect(index);
        }
    };

    const getTabContainerStyle = () => {
        switch (verticalJustify) {
            case 'full':
                return { width: '100%' };
            case 'scroll':
                return { overflowX: 'auto', width: '100%' };
            case 'left':
                return { justifyContent: 'flex-start' };
            case 'right':
                return { justifyContent: 'flex-end' };
            default:
                return {};
        }
    };

    return (
        <div style={{ display: 'flex', marginBottom:'10px', ...getTabContainerStyle(), ...customStyle }}>
            {tabs.map((tab, index) => (
                <div
                    key={index}
                    onClick={() => handleTabClick(index)}
                    style={{
                        padding: '10px 20px',
                        cursor: 'pointer',
                        borderBottom: activeTab === index ? '2px solid blue' : 'none',
                        // width: verticalJustify === 'scroll' ? '150px' : undefined,
                        width: '100%',
                        textAlign: 'center',
                        ...customTabStyle,
                        ...(activeTab === index ? customSelectedTabStyle : {}),
                    }}
                >
                    {tab}
                </div>
            ))}
        </div>
    );
};


export default CustomTabs;