// ** React Imports
import { useState, Fragment, useEffect } from "react";
// ** Reactstrap Imports
import { Card, CardBody, Button, ListGroup, ListGroupItem, Modal, ModalHeader, ModalBody } from 'reactstrap'

// ** Third Party Imports
import { useDropzone } from 'react-dropzone'
import { FileText, X, DownloadCloud, ArrowLeft } from 'react-feather'
import { FaFilePdf, FaFileImage, FaFileAlt } from 'react-icons/fa';
import toast from "react-hot-toast";

import { useParams } from 'react-router-dom';


import StatementProcess from "./StatementProcess";

//Bank statement API

import { bankStatementRequest } from "../../../services/bankStatement";

const UploadStatement = (props) => {
    // const { id } = useParams();
    const { selectedItem, showPopup, toggle } = props;

    // ** State
    // const { handleFileUpload, handleFileRemove } = props;
    const [files, setFiles] = useState([])
    const [file, setFileData] = useState("");
    const [showProcess, setShowProcess] = useState(false);

    const handleFileUpload = (pdfFile) => {
        if (pdfFile[0] && pdfFile[0].type === "application/pdf") {
            setFileData(pdfFile[0]);
        }
    };

    const handleFileRemove = () => {
        setFileData("");
    };

    useEffect(() => {
        if (files && files.length) {
            handleFileUpload(files);
        }
    }, [files]);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: false,
        accept: 'application/pdf',
        onDrop: (acceptedFiles, rejectedFiles) => {
            if (rejectedFiles.length) {
                toast.error('You can only upload PDFs!');
            } else {
                setFiles([...files, ...acceptedFiles.map(file => Object.assign(file))]);
            }
        },
    });

    const renderFilePreview = file => {
        if (file.type === 'application/pdf') {
            return <FaFilePdf size='28' />;
        } else if (file.type.startsWith('image')) {
            return <img className='rounded' alt={file.name} src={URL.createObjectURL(file)} height='28' width='28' />;
        } else {
            return <FileText size='28' />;
        }
    }

    const handleRemoveFile = file => {
        const uploadedFiles = files;
        const filtered = uploadedFiles.filter(i => i.name !== file.name);
        setFiles([...filtered]);
        handleFileRemove();
    }

    const renderFileSize = size => {
        if (Math.round(size / 100) / 10 > 1000) {
            return `${(Math.round(size / 100) / 10000).toFixed(1)} mb`
        } else {
            return `${(Math.round(size / 100) / 10).toFixed(1)} kb`
        }
    }

    const handleRemoveAllFiles = () => {
        setFiles([]);
    }
    const handleUploadFiles = async () => {
        setShowProcess(true);

        if (files && selectedItem?.name) {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('bank_name', selectedItem?.name);

            try {
                const request = await bankStatementRequest(formData);
                console.log(request);
                props.handleChange(files);
            } catch (err) {
                console.log(err, 'err');
            }
        }
    }

    const fileList = files.map((file, index) => (
        <ListGroupItem key={`${file.name}-${index}`} className='d-flex align-items-center justify-content-between mb-1'>
            <div className='file-details d-flex align-items-center'>
                <div className='file-preview me-1'>{renderFilePreview(file)}</div>
                <div>
                    <p className='file-name mb-0'>{file.name}</p>
                    <p className='file-size mb-0'>{renderFileSize(file.size)}</p>
                </div>
            </div>
            <Button color='danger' outline size='sm' className='btn-icon' onClick={() => handleRemoveFile(file)}>
                <X size={14} />
            </Button>
        </ListGroupItem>
    ))


    // if (showProcess) {
    //     return (
    //         <StatementProcess selectedItem={selectedItem} files={files} />
    //     )
    // }
    return (
        <Modal isOpen={showPopup} toggle={toggle} className="upload-popup">
            <ModalBody>
                <Card className={'custom-card bsp-upload'}>
                    {/* <CardHeader> */}
                    <button className="back-btn" onClick={toggle}> <ArrowLeft size={20} /></button>
                    <div className="bank-info my-2">
                        <img src={selectedItem?.image_url || ''} />
                        <h2>{selectedItem?.name || ''}</h2>
                    </div>
                    <h2 className="h2 text-center">Upload Your Bank Statement</h2>
                    {/* </CardHeader> */}
                    <CardBody>
                        <div {...getRootProps({ className: 'dropzone' })}>
                            <input {...getInputProps()} />
                            <div className='d-flex align-items-center justify-content-center flex-column'>
                                <DownloadCloud size={24} />
                                <small>Drop Files here or click to upload</small>
                            </div>
                        </div>
                        {files.length ? (
                            <Fragment>
                                <ListGroup className='my-2'>{fileList}</ListGroup>
                                <div className='d-flex justify-content-center'>
                                    <Button className='me-1' color='danger' onClick={handleRemoveAllFiles}>
                                        Remove All
                                    </Button>
                                    <Button color='primary' onClick={handleUploadFiles}>Upload Files</Button>
                                </div>
                            </Fragment>
                        ) : null}
                    </CardBody>
                </Card>
            </ModalBody>

            {/* {showProcess && <StatementProcess selectedItem={selectedItem} files={files} />} */}
        </Modal>

    )
}

export default UploadStatement;