import React from "react"
import './index.css'
import { useNavigate } from "react-router-dom";

const CustomCard=(props)=>{
    const navigate = useNavigate();
    const handleCardClick = (path) => {
        if(!path) {
            props.showModel(true)
        } else {
            navigate(props.nav, {state: {id:props.contentType, name:props.heading }})
        }

    }
    return(
        <div className="o_card01" onClick={(nav)=>{handleCardClick(props.nav)}}>
          <img src={props.image} alt="image"/>
          <p className="o_heading">{props.heading}</p>
          <p className="o_text">{props.subHeading}</p>
        </div>
    )
}
export default CustomCard