import { useState } from "react";
import BlogLinkSection from "./BlogLinkSection";
import './style.scss';

const Banner = () =>{
    const [showBlogLink, setShowBlogLink] = useState(true);

    let userName = localStorage.getItem('first_name') + ' ' + localStorage.getItem('last_name')
    return(
        <div className="banner" id="main-banner">
            <h1>Welcome {userName}, 👋</h1>
            {
                showBlogLink && <BlogLinkSection setShowBlogLink={setShowBlogLink}/>
            }
            
        </div>
    )
}

export default Banner;