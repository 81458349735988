import { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import notificationIcon from "@src/assets/images/pages/notificationIcon.png";

const NotificationModel = ({ open, toggleNotification }) => {

    const handleToggle = () => {
        toggleNotification()
    }
    return (
        <div>
            <Modal isOpen={open} toggle={handleToggle} backdrop="static" className='notification-modal'>
                <button onClick={handleToggle} className='btn-close'></button>
                <ModalBody>
                    <div className='notification-modal__icon'>
                        <img src={notificationIcon} alt='/' />
                    </div>
                    <p>We are in the process of integration and will go live soon. Stay tuned for the exciting launch!</p>

                    <Button color='primary' onClick={handleToggle}>Okay</Button>
                </ModalBody>
            </Modal>
        </div>
    )
}

export default NotificationModel;